import "../CSS/achievements.css"
import { Grid } from "@mui/material"
import ParticlesCustom from "./Particles"
import useMouseAnimation from "../Utils/useMouseAnimation"

const achievementsList = [
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/MkB8sG6Kn2FgPr2wHruBB8B5MUZzjwhdvchcbxd7.png",
    name: "Involvement (100XP)",
    desc: "Share a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/zDy2G0ljtruTGzChKPXXkspU1ZuEJCDPdeCpYzdy.png",
    name: "Sharing is Caring (100XP)",
    desc: "Share My LaserMaxx."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/H9yNS6oI0mBagmF0NSPHzXxJiDnXGUZowBwVODDo.png",
    name: "MVP (500XP)",
    desc: "Get first place in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/Xx6TIVM1HGWtK8mE4PPtlklrzMqyLaRK0bgxqp7Q.png",
    name: "Assassin (250XP)",
    desc: "Get the highest accuracy in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/UkcFcXLsgjgSXGNuZGIXEJYGVMFLPy8UWIayi7dO.png",
    name: "DIY (100XP)",
    desc: "Share your profile."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/YhfDhwTFeZ60oznD3QA7qKDE4kRZTsK3skpXtjLY.png",
    name: "Trigger-finger (250XP)",
    desc: "Get the most shots in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/9mt5pqAXXSrawtQSEowmNhPGM0XEypOFVtWOBXOm.png",
    name: "Predator (250XP)",
    desc: "Hit the most opponents in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/avN4dClzZQGuklnJ1avrLjC9GD5cZ7u2Z65lP0Oa.png",
    name: "Competitor (75XP)",
    desc: "Play a game of LaserMaxx."
  }
]

const Achievements = () => {
  useMouseAnimation()

  return (
    <>
      <div style={{ position: "relative", textAlign: "center", zIndex: "2" }}>
        <h1 className="achievements__page-title">Achievements</h1>
      </div>
      <ParticlesCustom/>
      <div className="achievements__container">
        <Grid container spacing={2} className="achievements__content-container">
          {
            achievementsList.map((achievement, index) => {
              return (
                <Grid item md={6} xs={12} key={index}>
                  <div className="achievements__content-element glassmorphism shiny">
                    <img className="achievements__badge" src={achievement.image} alt=""/>
                    <div className="achievements__title-container">
                      <h1 className="achievements__title">{achievement.name}</h1>
                      <p className="achievements__description">{achievement.desc}</p>
                    </div>
                  </div>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </>
  )
}

export default Achievements