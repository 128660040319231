import { useState } from "react"

import { Box, Container, Tabs, Tab, AppBar } from "@mui/material"

import TopRank from "./TopRanking"
import BestOfTheMonth from "./BestOfTheMonth"
import TabPanel from "../TabPanel"

import { a11yProps } from "../Utils/utils"

import "../CSS/topRanking-arene.css"

const TopRanking = () => {

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Container className="top-ranking__container">
      <Box className="glassmorphism top-ranking__box">
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider", display: "flex" }}>
            <AppBar position="static" sx={{ background: "#12121200" }}>
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Top Ranking" {...a11yProps(0)} />
                <Tab label="Migliore del mese" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
          </Box>
          <Box sx={{ maxWidth: "100vw", maxHeight: "70vh" }}>
            <TabPanel secondHalf value={value} index={0}>
              <TopRank/>
            </TabPanel>
            <TabPanel secondHalf value={value} index={1}>
              <BestOfTheMonth/>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default TopRanking