import React from "react"
// import ReactDOM from 'react-dom/client';
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { store } from "./components/store"
import { BrowserRouter } from "react-router-dom"
import { createBrowserHistory } from "history"

// const root = ReactDOM.createRoot(document.getElementById('root'));
const history = createBrowserHistory()

// root.render(
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store} history={history}>
      <App/>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
