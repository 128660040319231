import { useEffect, useReducer } from "react"
import { useSelector } from "react-redux"

import {
  CircularProgress, Divider, Container, Grid, Typography,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from "@mui/material"

import { styled } from "@mui/material/styles"
import { tableCellClasses } from "@mui/material/TableCell"

import { callProcess } from "../Utils/utils"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#12121200",
    color: "#FFFFFFB3",
    textTransform: "capitalize"
  },
  [`&.${tableCellClasses.head}:first-of-type`]: {
    color: "#1EDCFF"
  },
  [`&.${tableCellClasses.body}`]: {
    color: "#FFFFFFB3"
  }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:last-child td, &:last-child th": {
    border: 0
  },
  "&.styled-row__self-report > *": {
    color: "#1EDCFF !important"
  }
}))

const StyledGridItem = styled(Paper)(({ theme }) => ({
  // backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  backgroundImage: "none",
  // padding: theme.spacing(1),
  textAlign: "center"
  // color: theme.palette.text.secondary,
}))

const StyledGridImg = styled("img")({
  margin: "auto",
  display: "block",
  maxWidth: "60%",
  width: "20vw"
})

const MatchReport = ({ currMatchID }) => {
  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&matchID=" + currMatchID + "&processid=app_usermatchs"

  const [dataMatch, setDataMatch] = useReducer(
    (oldState, newState) => ({ data: newState.data, isLoading: newState.isLoading, foesScore: newState.foesScore }),
    {
      data: [],
      foesScore: 0,
      isLoading: true
    }
  )

  useEffect(() => {
    callProcess({ username, password, matchID: currMatchID, processid: "app_usermatchs" })
      .then((res) => {
        if (!res.match_data) {
          setDataMatch({ data: [], isLoading: false })
          return
        }

        setDataMatch({
          data: res.match_data,
          isLoading: false,
          foesScore: res.match_data.FOES.reduce((acc, { SCORES }) => acc + (+SCORES), 0)
        })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [username, password, currMatchID])

  return (
    <>
      {
        dataMatch.isLoading ?
          <CircularProgress className="circular-progress--centered"/>
          :
          <Container sx={{ padding: "2%", width: "100%" }}>
            <Grid container spacing={2} sx={{ paddingBottom: "3%" }}>
              <Grid item xs={12} md={3} lg={3}>
                <StyledGridItem sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  <StyledGridImg
                    alt="complex"
                    src={
                      dataMatch.data && dataMatch.data.GAME_MODE_LOGOURL ?
                        dataMatch.data.GAME_MODE_LOGOURL : "https://via.placeholder.com/150"
                    }
                  />
                </StyledGridItem>
              </Grid>
              <Grid item xs={12} md={9} lg={9} container>
                <Grid container spacing={1} rowSpacing={1}>
                  {
                    [
                      { title: "Modalità", value: dataMatch.data.GAME_MODE },
                      { title: "Arena", value: dataMatch.data.ARENA_NAME },
                      { title: "Punteggio", value: dataMatch.data.SCORES },
                      { title: "Data", value: dataMatch.data.DATE },
                      { title: "Ora", value: dataMatch.data.HOUR }
                    ].map(({ title, value }, index) => (
                      <Grid item xs={6} md={6} lg={6} key={"PARTITA-INFO-" + index} sx={{ textAlign: "left" }}>
                        <Typography sx={{ marginBottom: "2%" }}>
                          {title}: <span style={{
                            color: "white",
                            display: "block",
                            marginLeft: "3%",
                            fontWeight: "bold"
                          }}>{value}</span>
                        </Typography>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={1} rowSpacing={1}>
              <Grid item xs={12} md={12} lg={12}>
                <Typography variant="h3">{dataMatch.data.NAME}</Typography>
                <Divider sx={{ marginBottom: "3%" }}/>
              </Grid>
            </Grid>
            <Grid container spacing={1} rowSpacing={1}>
              {
                [
                  { title: "rank", value: dataMatch.data.RANKING },
                  { title: "precisione", value: dataMatch.data.ACCUARCY },
                  { title: "colpi subiti", value: dataMatch.data.DEATH },
                  { title: "basi conquistate", value: dataMatch.data.CAPTURES },
                  { title: "colpi sparati", value: dataMatch.data.TOTSHOTS },
                  { title: "colpi inflitti", value: dataMatch.data.KILLS },
                  { title: "ricariche", value: dataMatch.data.RELOADS }
                ].map(({ title, value }, index) => (
                  <Grid item xs={6} md={4} lg={4} key={"STATS-" + index} sx={{ textAlign: "left" }}>
                    <Typography sx={{ marginBottom: "2%" }}>
                      {title[0].toUpperCase() + title.slice(1)}:
                      <span style={{
                        color: "white",
                        display: "block",
                        marginLeft: "3%",
                        fontWeight: "bold"
                      }}>{value}</span>
                    </Typography>
                  </Grid>
                ))
              }
            </Grid>
            <Divider sx={{ marginBottom: "3%", width: "75%", marginX: "auto" }}/>
            <Grid container spacing={1} rowSpacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                <TableContainer component={Paper} sx={{ maxWidth: "80vw", marginX: "auto" }}>
                  <Table sx={{ boxSizing: "content-box" }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell size="medium">Team Alleato
                          <span
                            style={{ display: "block" }}>{dataMatch.data.ALLIES_TEAM_SCORES}</span></StyledTableCell>
                        <StyledTableCell align="center">colpi subiti</StyledTableCell>
                        <StyledTableCell align="center">colpi infliti</StyledTableCell>
                        <StyledTableCell align="right">punteggio</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataMatch.data.ALLIES && dataMatch.data.ALLIES.map((allie, index) => {
                          return (
                            <StyledTableRow key={"ALLIES-" + index}
                              className={allie.NAME === username ? "styled-row__self-report" : ""}>
                              <StyledTableCell scope="row" sx={{ alignItems: "center" }}>{allie.NAME}</StyledTableCell>
                              <StyledTableCell align="center">{allie.DEATH}</StyledTableCell>
                              <StyledTableCell align="center">{allie.KILLS}</StyledTableCell>
                              <StyledTableCell align="right">{allie.SCORES}</StyledTableCell>
                            </StyledTableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12} lg={12}>
                <TableContainer component={Paper} sx={{ maxWidth: "80vw", marginX: "auto" }}>
                  <Table sx={{ boxSizing: "content-box" }}>
                    <TableHead>
                      <TableRow>
                        <StyledTableCell size="medium">Team Avversario<span
                          style={{ display: "block" }}>{dataMatch.foesScore}</span>
                        </StyledTableCell>
                        <StyledTableCell align="center">colpi subiti</StyledTableCell>
                        <StyledTableCell align="center">colpi infliti</StyledTableCell>
                        <StyledTableCell align="right">punteggi</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        dataMatch.data.FOES && dataMatch.data.FOES.map((foe, index) => {
                          return (
                            <StyledTableRow key={"FOES-" + index}>
                              <StyledTableCell scope="row" sx={{ alignItems: "center" }}>{foe.NAME}</StyledTableCell>
                              <StyledTableCell align="center">{foe.DEATH}</StyledTableCell>
                              <StyledTableCell align="center">{foe.KILLS}</StyledTableCell>
                              <StyledTableCell align="right">{foe.SCORES}</StyledTableCell>
                            </StyledTableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Container>
      }
    </>
  )
}

export default MatchReport
