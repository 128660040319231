import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { Link } from "react-router-dom"
import {
  Paper, CircularProgress, Avatar,
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material"

import { callProcess } from "../Utils/utils"

const TopRankArena = () => {

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  const params = useParams()

  const [data, setData] = useState({
    data: [],
    isLoading: true
  })

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&arenaID=" + params.arenaID + "&language=&processid=app_toprank"
    callProcess({ username, password, arenaID: params.arenaID, processid: "app_toprank" })
      .then((res) => {
        setData({ data: res, isLoading: false })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [username, password, params.arenaID])

  return (
    <TableContainer component={Paper} sx={{ width: "100%", display: "flex", maxHeight: "52vh", maxWidth: "85vw"  }}
      className="container--scrollable">
      {
        data.isLoading ?
          <CircularProgress className="circular-progress--centered"/>
          :
          <Table stickyHeader style={{ maxHeight: "52vh" }}>
            <TableHead className="table__custom--head">
              <TableRow>
                <TableCell align="left" className="table__custom--cell">Giocatore</TableCell>
                <TableCell align="right" className="table__custom--cell">Rank</TableCell>
                <TableCell align="right" className="table__custom--cell">Punteggio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table__custom--body">
              {
                data.isLoading ?
                  <TableRow className="table__custom--row"
                    sx={{ width: "410%", display: "flex", justifyContent: "center" }}>
                    <CircularProgress className="circular-progress--centered"/>
                  </TableRow>
                  :
                  data.data.dati && data.data.dati.map((toprank, index) => {
                    return (
                      <TableRow
                        className="table__custom--row"
                        component={Link} to={"/Profilo/" + toprank.user_id}
                        key={index}
                        sx={{ textDecoration: "none", cursor: "pointer" }}
                      >
                        <TableCell
                          className="table__custom--cell__white"
                          component="th"
                          scope="row"
                          sx={{ display: "flex", alignItems: "center" }}>
                          <Avatar src={toprank.avatar}/>&nbsp;&nbsp;{toprank.name}
                        </TableCell>
                        <TableCell className="table__custom--cell__white" align="right">{toprank.rank}</TableCell>
                        <TableCell className="table__custom--cell__white" align="right">{toprank.score}</TableCell>
                      </TableRow>
                    )
                  })
              }
            </TableBody>
          </Table>
      }
    </TableContainer>
  )
}

export default TopRankArena