import { AppBar, Tab, Tabs } from "@mui/material"
import { a11yProps } from "../Utils/utils"
import TopRankArena from "./TopRankArena"
import TopOfTheMonth from "./TopOfTheMonth"
import TabPanel from "../TabPanel"
import { useState } from "react"

const RankTabPanel = ({ data }) => {
  const [value, setValue] = useState(0)

  return (
    <>
      <AppBar position="static" sx={{ background: "#12121200", width: "100%", height: "100%" }}>
        <Tabs value={value} onChange={(_, tab) => setValue(tab)}>
          <Tab label="Rank arena" {...a11yProps(0)} />
          <Tab label="Migliore del mese" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      {
        [
          TopRankArena,
          TopOfTheMonth
        ].map((Children, index) => {
          return (
            <TabPanel
              value={value}
              index={index}
              key={index}
              secondHalf
              sx={{ overflowY: "hidden", p: 0, paddingTop: 1 }}
            >
              <Children/>
            </TabPanel>

          )
        })
      }
    </>
  )
}

export default RankTabPanel