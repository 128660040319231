import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material"
import { primaryColor } from "../Utils/constants"
// import StatsGraph from "../User/StatsGraph"
import Graph from "./Graph"

const achievementsList = [
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/MkB8sG6Kn2FgPr2wHruBB8B5MUZzjwhdvchcbxd7.png",
    name: "Involvement (100XP)",
    desc: "Share a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/zDy2G0ljtruTGzChKPXXkspU1ZuEJCDPdeCpYzdy.png",
    name: "Sharing is Caring (100XP)",
    desc: "Share My LaserMaxx."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/H9yNS6oI0mBagmF0NSPHzXxJiDnXGUZowBwVODDo.png",
    name: "MVP (500XP)",
    desc: "Get first place in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/Xx6TIVM1HGWtK8mE4PPtlklrzMqyLaRK0bgxqp7Q.png",
    name: "Assassin (250XP)",
    desc: "Get the highest accuracy in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/UkcFcXLsgjgSXGNuZGIXEJYGVMFLPy8UWIayi7dO.png",
    name: "DIY (100XP)",
    desc: "Share your profile."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/YhfDhwTFeZ60oznD3QA7qKDE4kRZTsK3skpXtjLY.png",
    name: "Trigger-finger (250XP)",
    desc: "Get the most shots in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/9mt5pqAXXSrawtQSEowmNhPGM0XEypOFVtWOBXOm.png",
    name: "Predator (250XP)",
    desc: "Hit the most opponents in a game."
  },
  {
    image: "https://my.lasermaxx.com/storage/mylmx/achievements/avN4dClzZQGuklnJ1avrLjC9GD5cZ7u2Z65lP0Oa.png",
    name: "Competitor (75XP)",
    desc: "Play a game of LaserMaxx."
  }
].map((achievement, index) => ({
  ACHIEVEMENT_NAME: achievement.name,
  ACHIEVEMENT_DESCRIPTION: achievement.desc,
  ACHIEVEMENT_ICON: achievement.image
}))

const Statistics = ({ data }) => {

  data.achievements = achievementsList

  return (
    <div>
      <Box sx={{ display: "flex", padding: "2%" }}>
        <div style={{ width: "100%" }}>
          <Typography
            variant="h4"
            className="page__subtitle"
          >
            Statistiche
          </Typography>
          <Divider sx={{ marginBottom: "3%", marginTop: "1.5%" }}/>

          <div
            style={{
              textAlign: data && data.global_user_games_data ? "left" : "center",
              color: "white"
            }}
          >
            {
              data && data.global_user_games_data ?
                <Grid container className="statistic__container">
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Punteggio</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.global_user_games_data.global_score}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Rank</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.global_user_games_data.global_rank}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Partite</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.global_user_games_data.global_matchs_num}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Obbiettivi</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.global_user_games_data.global_captures}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Colpi sparati</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.global_user_games_data.global_totshots}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Vittorie</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.MATCHS_WINS ? data.MATCHS_WINS : 0}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Sconfitte</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.MATCHS_DEFEATS ? data.MATCHS_DEFEATS : 0}</Typography>
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Precisione</Typography>
                    <Typography variant="h6"
                      className="statistic__value">{data.global_user_games_data.global_accuarcy}</Typography>
                    {/* <Typography className="statistic__title">Data di registrazione</Typography>*/}
                    {/* <span className="statistic__value">{dateFormatter(data.USER_REGISTERED)}</span>*/}
                  </Grid>
                  <Grid item xs={6} md={4}>
                    <Typography variant="body2" className="statistic__title">Modalita preferita</Typography>
                    <Typography variant="h6" className="statistic__value">{data.PREFERRED_GAME_MODE || (
                      <br/>)}</Typography>
                  </Grid>
                </Grid>
                :
                <CircularProgress className="circular-progress--centered"/>
            }
          </div>
        </div>
      </Box>

      {/* <StatsGraph data={data}/>*/}
      <div className="radar--container">
        {
          data && data.global_user_games_data && data.global_average_data ?
            <Graph/>
            // <div></div>
            :
            <CircularProgress className="circular-progress--centered"/>
        }
      </div>

      <div className="achievement--container">
        <Typography
          variant="h4"
          className="page__subtitle"
          style={{ color: primaryColor.hex, textAlign: "center", textTransform: "uppercase" }}
        >
          Obbiettivi
        </Typography>
        <Divider sx={{ marginBottom: "3%", marginTop: "1.5%" }}/>
        <div className="achievement__list navbar--custom">
          {
            data && data.achievements ?
              data.achievements.map((achievement, index) => {
                return (
                  <div key={index} className="achievement__item">
                    <img src={achievement.ACHIEVEMENT_ICON} alt="achievement icon"/>
                  </div>
                )
              }
              )
              :
              <CircularProgress className="circular-progress--centered"/>
          }
        </div>
      </div>
    </div>
  )
}

export default Statistics