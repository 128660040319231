import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { Container, Avatar, Grid, Box, List, ListItem, ListItemText, ListItemAvatar } from "@mui/material"
import { callProcessPath, dbName } from "../Utils/constants"

import "../CSS/modalita.css"

const PageModalities = () => {

  const params = useParams()

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const userId = useSelector(state => state.counter.userId)

  const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&ID=" + params.pageID + "&userID=" + userId + "&language=&processid=app_modalities"

  const [data, setData] = useState([])

  useEffect(() => {
    fetch(url)
      .then(res => {
        if (!res.ok) {
          throw new Error("Data could not be fetched!")
        }

        return res.json()
      })
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })
  }, [url])

  return (
    <Container
      sx={{ padding: "5% !important" }}
    >
      <Box
        className="container--scrollable"
      >
        <List className="glassmorphism">
          {
            data.modality ?
              <ListItem>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItemAvatar>
                      <Avatar
                        src={data.modality.LOGOENCODED}
                        alt={data.modality.LOGO}
                        sx={{
                          width: "17vh", height: "17vh",
                          borderRadius: "10px",
                          margin: "auto"
                        }}
                      />
                    </ListItemAvatar>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ListItemText
                      primary={data.modality.TITOLO}
                      secondary={<div dangerouslySetInnerHTML={{ __html: data.modality.DESCRIZIONE }}/>}
                      sx={{ padding: "3%" }}/>
                  </Grid>
                </Grid>
              </ListItem> : ""
          }
        </List>
      </Box>
    </Container>
  )
}

export default PageModalities