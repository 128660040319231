import { useCallback, useEffect, useReducer, useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"

import {
  Container, Divider, Avatar, ListItem,
  Tabs, Tab, Box, CircularProgress, Grid
} from "@mui/material"

import { callProcessPath, imageBasePath, dbName, primaryColor } from "../Utils/constants"
import { a11yProps, dateFormatter } from "../Utils/utils"

import UserSettings from "./UserSettings"
import MatchesTable from "./MatchesTable"
import MatchReport from "./MatchReport"
import TabPanel from "../TabPanel"

import "../CSS/user.css"
import StatsGraph from "./StatsGraph"
import Alert, { alertReducer, initialState } from "../Alert"

const Profile = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const [alertData, setAlertData] = useReducer(alertReducer, initialState)

  const [value, setValue] = useState(searchParams.get("action") === "edit" ? 2 : 0)
  const [currMatch, setCurrMatch] = useState(0)

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&playerID=" + params.playerID + "&language=&processid=app_userdata"

  const [data, setData] = useState([])

  const fetchData = useCallback(async() => {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error("Data coud not be fetched!")
    } else {
      return response.json()
    }
  }, [url])

  useEffect(() => {
    fetchData()
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [fetchData])

  useEffect(() => {
    if (searchParams.get("action") === "edit") {
      setAlertData({ message: "Perfavore compila i campi del tuo profilo", resetter: setAlertData })
    }

  }, [searchParams])

  // data && data.global_user_games_data && data.global_average_data ?
  //   console.log("DATI!", data.global_average_data.average_global_tot_kills, data.global_average_data.average_global_totshots, data.global_average_data.average_global_accuarcy, data.global_average_data.average_global_captures, data.global_average_data.average_global_score)
  //   :
  //   console.log("niente")

  return (
    <>
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          sx={{ width: "100%", margin: "auto", padding: "3%", flexDirection: "row !important" }}
          style={{ gap: "2%", rowGap: "1vh" }}
        >
          <Grid
            item
            xs={12} md={4} lg={4}
            className="glassmorphism"
            sx={{ padding: "3%" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Avatar sx={{ width: "70px", height: "70px" }} src={imageBasePath + data.AVATAR} alt={data.AVATAR}/>
              <ListItem>
                <h3>{data.DISPLAY_NAME}</h3>
                {/* <ListItemText bold primary={data.DISPLAY_NAME}*/}
                {/*  secondary={<div><p>Arena di provenienza: {data.ARENA_ORIGIN_NAME}</p></div>}*/}
                {/* />*/}
              </ListItem>
            </Box>
            {/* <br/>*/}
            {/* <br/>*/}
            <Divider/>
            <Box sx={{ display: "flex", padding: "2%" }}>
              <div style={{ width: "100%" }}>
                <h4 style={{ color: primaryColor.hex }}>Statistiche</h4>
                <div style={{ textAlign: data && data.global_user_games_data ? "left" : "center", color: "white" }}>
                  {
                    data && data.global_user_games_data ?
                      <div>
                        <p>Punteggio Totale: {data.global_user_games_data.global_score}</p>
                        <p>Global Rank: {data.global_user_games_data.global_rank}</p>
                        <p>Partite giocate: {data.global_user_games_data.global_matchs_num}</p>
                        <p>Obbiettivi conquistati: {data.global_user_games_data.global_captures}</p>
                        <p>Colpi inflitti: {data.global_user_games_data.global_kills}</p>
                        <p>Vittorie: {data.MATCHS_WINS ? data.MATCHS_WINS : 0}</p>
                        <p>Sconfitte: {data.MATCHS_DEFEATS ? data.MATCHS_DEFEATS : 0}</p>
                        <p>Modalita di gioco preferita: {data.PREFERRED_GAME_MODE}</p>
                        <p>Data di registrazione: {dateFormatter(data.USER_REGISTERED)}</p>
                      </div>
                      :
                      <CircularProgress className="circular-progress--centered"/>
                  }
                </div>
              </div>
            </Box>
            <br/>
            <Divider/>
            <Tabs variant="fullWidth" value={value} onChange={(_, newValue) => setValue(newValue)}
              sx={{ display: "flex" }}>
              <Tab label="Radar" {...a11yProps(0)} sx={{ display: "none" }}/>
              {data.useranagrafica ? <Tab label="Partite" {...a11yProps(1)}/> : <div></div>}
              {data.useranagrafica ? <Tab label="Modifica Profilo" {...a11yProps(2)}/> : <div></div>}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={7} lg={7} className="glassmorphism">
            <TabPanel secondHalf value={value} index={0} style={{ height: "100%", width: "100%" }}>
              <div className="radar--container">
                {
                  data && data.global_user_games_data && data.global_average_data ?
                    <StatsGraph data={data}/>
                    :
                    <CircularProgress className="circular-progress--centered"/>
                }
              </div>
            </TabPanel>
            <TabPanel secondHalf value={value} index={1}>
              {
                username !== "WEBPUBLIC" ?
                  <MatchesTable
                    activeMatchReport={(matchID) => {
                      setCurrMatch(matchID)
                      setValue(3)
                    }}
                  />
                  : <></>
              }
            </TabPanel>
            <TabPanel secondHalf value={value} index={2}>
              <UserSettings/>
            </TabPanel>
            <TabPanel secondHalf value={value} index={3}>
              {
                username !== "WEBPUBLIC" ?
                  <MatchReport currMatchID={currMatch}/>
                  : <></>
              }
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
      <Alert alert={alertData}/>
    </>
  )
}

export default Profile
