import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import {
  Paper, CircularProgress, Avatar,
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow
} from "@mui/material"
import { Link } from "react-router-dom"

import { callProcess } from "../Utils/utils"

const TopOfTheMonth = () => {

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  const params = useParams()

  const [data, setData] = useState({
    data: [],
    isLoading: true
  })

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=dev&arenaID=" + params.arenaID + "&language=&filter=currentMonth&processid=app_toprank"
    callProcess({ username, password, processid: "app_toprank", filter: "currentMonth", arenaID: params.arenaID })
      .then((res) => {
        setData({ data: res, isLoading: false })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [password, params.arenaID, username])

  return (
    <TableContainer component={Paper} sx={{ width: "100%", display: "flex", maxHeight: "52vh", maxWidth: "85vw" }}
      className="container--scrollable">
      {
        data.isLoading ?
          <CircularProgress className="circular-progress--centered"/>
          :
          <Table stickyHeader style={{ maxHeight: "52vh" }}>
            <TableHead className="table__custom--head__opaque">
              <TableRow sx={{ backgroundColor: "#121212" }}>
                <TableCell align="left" className="table__custom--cell">Giocatore</TableCell>
                <TableCell align="right" className="table__custom--cell">Rank</TableCell>
                <TableCell align="right" className="table__custom--cell">Punteggio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="table__custom--body">
              {
                data.data.dati &&
                data.data.dati.map((toprank, index) => (
                  <TableRow
                    className="table__custom--row"
                    component={Link} to={"/Profilo/" + toprank.user_id}
                    key={index}
                    sx={{ textDecoration: "none" }}
                  >
                    <TableCell
                      className="table__custom--cell__white"
                      component="th"
                      scope="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar src={toprank.avatar}/>&nbsp;&nbsp;{toprank.name}
                    </TableCell>
                    <TableCell align="right" className="table__custom--cell__white">{toprank.rank}</TableCell>
                    <TableCell align="right" className="table__custom--cell__white">{toprank.score}</TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
      }
    </TableContainer>
  )
}

export default TopOfTheMonth