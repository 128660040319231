import { Tabs, Tab } from "@mui/material"
import { Link, useLocation } from "react-router-dom"

const Nav = ({ handleTabChange, classes = {}, isForMobile, playerId = null, forMobile, ...others }) => {
  const { pathname } = useLocation()

  let tab
  if (pathname === "/" || pathname === "/Home") {
    tab = 3
  } else if (pathname === "/TopRanking") {
    tab = 4
  } else if (pathname.startsWith("/Modalita")) {
    tab = 5
  } else if (pathname.startsWith("/Arene") || pathname.toLowerCase().match("aren(a|e)")) {
    tab = 6
  } else if (pathname.startsWith("/Profilo")) {
    tab = 8
  } else {
    tab = null
  }

  return (
    <Tabs
      sx={{ widht: "100%", paddingX: "5%" }}
      onChange={(_, newTab) => {
        handleTabChange(newTab)
      }}
      value={tab === null ? null : (tab - (forMobile ? 1 : 0))}
      aria-label="Navigation Tabs"
      variant="scrollable"
      scrollButtons="auto"
      className={classes.tabs}
      {...others}
    >
      {forMobile ? null : <Tab sx={{ pointerEvents: "none" }} index={0}/>}
      <Tab sx={{ pointerEvents: "none" }} index={-1} value={-1}/>
      <Tab sx={{ pointerEvents: "none" }} index={-2}/>
      {
        [
          { label: "Home", to: "Home" },
          { label: "Top Ranking", to: "TopRanking" },
          { label: "Modalità", to: "Modalita" },
          { label: "Arene", to: "Arene" },
          {
            label: "Apri un'arena",
            to: "/",
            onClick: () => window.open("https://www.lasergame-xraid.com/index.php/en/", "_blank")
          }
        ].map(({ label, to, onClick }, index) => (
          <Tab
            className={classes.tab}
            component={Link}
            to={to}
            label={label}
            onClick={onClick}
            index={index + 1}
            key={index + label}
          />
        ))
      }
      <Tab
        className={classes.tab}
        component={Link}
        to={
          // eslint-disable-next-line eqeqeq
          playerId != null ? ("/Profilo/" + playerId) : "/Login"
        }
        label="Profilo"
        index={6}
      />
    </Tabs>
  )
}

export default Nav