const apiBasePath = "https://backend.x-raid.net/"
const imageBasePath = "https://backend.x-raid.net/archive/xraid/repository/"
const callProcessPath = "https://backend.x-raid.net/includes/io/CallProcessExt.php"
const dbName = "dev"

const primaryColor = {
  hex: "#1EDCFF",
  rgb: "30, 220, 255"
}

export {
  apiBasePath,
  imageBasePath,
  callProcessPath,
  dbName,
  primaryColor
}