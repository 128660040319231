import { createSlice } from "@reduxjs/toolkit"


const initialState = {
  value: 0,
  status: "idle",
  language: "en",
  username: "WEBPUBLIC",
  password: "WEBPUBLIC",
  userID: 3,
  userName: "",
  loginStatus: "idle",
  validationLogin: false,
  userAvatar: "",
  data: []
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUser: (state, action) => {
      state.username = action.payload[0]
      state.password = action.payload[1]
      state.validationLogin = action.payload[2]
      state.userName = action.payload[3]
      state.UserAnagraficaId = action.payload[4]
      state.userAvatar = action.payload[5]
    }
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
  }
})

export const { setUser } = counterSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCount = (state) => state.counter.value

export default counterSlice.reducer