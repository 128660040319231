import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import {
  Container, Avatar, Paper,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material"
import { Link } from "react-router-dom"

import { callProcessPath, dbName } from "../Utils/constants"

const BestOfTheMonth = () => {

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const userId = useSelector(state => state.counter.userId)

  const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&ID=&userID=" + userId + "&language=&filter=currentMonth&processid=app_toprank"

  const [data, setData] = useState([])

  useEffect(() => {
    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error("Data could not be fetched!")
        }

        return response.json()
      })
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [url])

  return (
    <Container sx={{ padding: 0, width: "100%" }}>
      <TableContainer component={Paper} className="table__container--scrollable-in-view" sx={{ display: "flex" }}>
        <Table stickyHeader>
          <TableHead className="table__custom--head__opaque">
            <TableRow>
              <TableCell className="table__custom--cell">Giocatore</TableCell>
              <TableCell className="table__custom--cell" align="left">Rank</TableCell>
              <TableCell className="table__custom--cell" align="left">Punteggio</TableCell>
              {/* <TableCell className="table__custom--cell" align="left">Arena</TableCell>*/}
            </TableRow>
          </TableHead>
          <TableBody className="table__custom--body">
            {
              data.dati && data.dati.map((bestOfTheMonth, index) => {
                return (
                  <TableRow
                    className="table__custom--row"
                    component={Link}
                    to={"/Profilo/" + bestOfTheMonth.user_id}
                    key={index}
                    sx={{ textDecoration: "none" }}>
                    <TableCell
                      className="table__custom--cell__white"
                      component="th"
                      scope="row"
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar {...(bestOfTheMonth.avatar ? { src: bestOfTheMonth.avatar } : {})}/>&nbsp;&nbsp;{bestOfTheMonth.name}
                    </TableCell>
                    <TableCell className="table__custom--cell__white" align="left">{bestOfTheMonth.score}</TableCell>
                    <TableCell className="table__custom--cell__white" align="left">{bestOfTheMonth.rank}</TableCell>
                    {/* <TableCell className="table__custom--cell__white"*/}
                    {/*  align="left">{bestOfTheMonth.arenaprovenienza}</TableCell>*/}
                  </TableRow>
                )
              })
            }
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default BestOfTheMonth