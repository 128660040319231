import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import {
  Box, CircularProgress, Avatar, List, ListItem, ListItemText, ListItemAvatar, Container, Grid, Button
} from "@mui/material"

import { callProcessPath, dbName } from "../Utils/constants"

import "../CSS/modalita.css"

const Modalita = () => {

  const navigate = useNavigate()

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const userId = useSelector(state => state.counter.userId)

  const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&ID=&userID=" + userId + "&language=&processid=app_modalities"

  const [data, setData] = useState({
    data: [],
    isLoading: true
  })

  useEffect(() => {
    fetch(url)
      .then(res => {
        if (!res.ok) {
          throw new Error("Data could not be fetched!")
        }

        return res.json()
      })
      .then((res) => {
        setData({ data: res, isLoading: false })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [url])

  return (
    <Container
      sx={{ padding: "5% !important" }}
    >
      <Box
        className={"container--scrollable glassmorphism" + (data.isLoading ? " arene__box" : "")}
        sx={{ display: "flex" }}
      >
        {
          data.isLoading ?
            <CircularProgress className="circular-progress--centered" sx={{ marginY: "2%" }}/>
            :
            <List className="modalita__list">
              {data.data.modalities && data.data.modalities.map((modalita, index) => (
                <ListItem
                  className="modalita__list-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/Modalita/" + modalita.ID)
                  }}
                  key={index}
                >
                  <Grid
                    container
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                      <ListItemAvatar>
                        <Avatar
                          src={modalita.LOGOENCODED}
                          alt={modalita.LOGO}
                          variant="square"
                          sx={{ height: "10vh", width: "10vh", borderRadius: "10px" }}
                        />
                      </ListItemAvatar>
                    </Grid>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                      <ListItemText
                        primary={modalita.TITOLO}
                        secondary={modalita.ESTRATTO}
                        sx={{ padding: "1vw" }}/>
                      <Button style={{ float: "right", marginRight: "2%" }} variant="outlined"
                        onClick={() => navigate("/Modalita/" + modalita.ID)}>Leggi di
                        più</Button>
                    </Grid>
                  </Grid>
                </ListItem>
              ))
              }
            </List>
        }
      </Box>
    </Container>
  )
}

export default Modalita