import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import {
  Avatar, Container, Divider,
  ListItem, ListItemText, Tab, Tabs, Box, Grid
} from "@mui/material"

import MainMap from "../Utils/gmap"
import Gallery from "../Gallery"
import TabPanel from "../TabPanel"

import { a11yProps, callProcess } from "../Utils/utils"
import RankTabPanel from "./RankTabPanel"

const PageArene = () => {
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const params = useParams()

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const userId = useSelector(state => state.counter.userId)

  const [data, setData] = useState([])

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&ID=" + params.arenaID + "&userID=" + userId + "&language=&processid=app_arenas"
    callProcess({ username, password, userId, processid: "app_arenas", ID: params.arenaID })
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [password, params.arenaID, userId, username])

  return (
    <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Grid
        container
        alignItems="stretch"
        justifyContent="center"
        sx={{ width: "100%", margin: "auto", padding: "5%", flexDirection: "row !important" }}
        style={{ gap: "2%", rowGap: "1vh" }}
      >
        {
          data.arena ?
            <>
              <Grid
                item
                xs={12} md={4} lg={4}
                className="glassmorphism"
                sx={{ padding: "3%" }}
              >
                <Box sx={{ marginBottom: "2%" }}>
                  <Avatar
                    sx={{
                      height: "30vh",
                      width: "30vh",
                      padding: "4%",
                      borderRadius: "2%"
                    }}
                    style={{
                      maxWidth: "50vw",
                      width: "100%",
                      height: "100%"
                    }}
                    variant="square"
                    src={data.arena.LOGOENCODED} alt={data.arena.LOGO}/>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-evenly", marginY: "2%" }}>
                  <Avatar
                    src={require("../../assets/facebook.png")}
                    onClick={() => window.open("https://www.facebook.com/" + data.arena.FACEBOOK + "", "_blank")}
                    sx={{ cursor: "pointer", width: "20px", height: "20px" }}
                    variant="square"
                  />
                  <Avatar
                    src={require("../../assets/instagram.png")}
                    onClick={() => window.open("https://www.instagram.com/" + data.arena.INSTAGRAM + "", "_blank")}
                    sx={{ cursor: "pointer", width: "20px", height: "20px" }}
                    variant="square"
                  />
                  {
                    data.arena.lat && data.arena.lng ?
                      <Avatar
                        src={require("../../assets/maps.png")}
                        onClick={() => handleChange("", 1)}
                        sx={{ cursor: "pointer", width: "20px", height: "20px" }}
                        variant="square"
                      />
                      : <></>
                  }
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                  {/* <Avatar*/}
                  {/*  sx={{*/}
                  {/*    height: "30vh",*/}
                  {/*    width: "30vh",*/}
                  {/*    padding: "4%",*/}
                  {/*    borderRadius: "2%"*/}
                  {/*  }}*/}
                  {/*  style={{*/}
                  {/*    maxWidth: "50vw",*/}
                  {/*    width: "100%",*/}
                  {/*    height: "100%"*/}
                  {/*  }}*/}
                  {/*  variant="square"*/}
                  {/*  src={data.arena.LOGOENCODED} alt={data.arena.LOGO}/>*/}
                  <ListItem sx={{ alignItems: "flex-start", flexDirection: "column" }}>
                    <h3 style={{ display: "block" }}>{data.arena.DESCRIZIONE}</h3>
                    <ListItemText
                      secondary={
                        <>
                          {/* <span style={{ display: "block" }}>{data.arena.CITTA} - {data.arena.PROVINCIA}</span>*/}
                          <span style={{ display: "block" }}>
                            <a style={{ color: "white" }}
                              href={"tel:" + data.arena.TELEFONO}>Tel: {data.arena.TELEFONO}</a>
                          </span>
                          {
                            data.arena.WHATSAPP ?
                              (
                                <span style={{ display: "block", cursor: "pointer" }}>
                                  <a style={{ color: "white" }}
                                    href={"https://wa.me/" + data.arena.WHATSAPP}>Whatsapp: {data.arena.WHATSAPP}</a>
                                </span>
                              ) : <></>
                          }
                          <span style={{ display: "block" }}>
                            <a style={{ color: "white" }} href={"mailto:" + data.arena.EMAIL}>{data.arena.EMAIL}</a>
                          </span>
                          <span style={{ display: "block" }}>
                            <a style={{ color: "white" }}
                              href={`https://www.google.com/maps?q=${data.arena.INDIRIZZO}, ${data.arena.PROVINCIA}`}
                              target="__blank">Indirizzo: {data.arena.INDIRIZZO}, {data.arena.PROVINCIA}</a>
                          </span>
                          {
                            data.arena.SITOWEB ?
                              (
                                <span style={{ display: "block", cursor: "pointer" }}>
                                  <a style={{ color: "white" }}
                                    href={data.arena.SITOWEB}>Link al sito</a>
                                </span>
                              ) : <></>
                          }
                        </>
                      }
                    />
                  </ListItem>
                </Box>
                <Divider sx={{ marginY: "3%" }}/>
                <Tabs variant="fullWidth" value={value} onChange={handleChange} sx={{ display: "flex" }}>
                  <Tab label="Rank Arena" {...a11yProps(0)}/>
                  <Tab label="Mappa" {...a11yProps(1)}
                    style={{ display: data.arena.lat && data.arena.lng ? "block" : "none" }}/>
                  <Tab label="Galleria" {...a11yProps(2)}/>
                </Tabs>
              </Grid>
              <Grid item xs={12} md={6} lg={7} className="glassmorphism" sx={{ padding: "3%" }}>
                <Box sx={{ height: "60vh", maxHeight: "60vh" }}>
                  <TabPanel secondHalf value={value} index={0} sx={{ padding: 0 }} style={{ padding: 0 }}>
                    <RankTabPanel data={data.arena}/>
                  </TabPanel>
                  <TabPanel secondHalf value={value} index={1}>
                    <div>
                      {
                        data.arena.lat && data.arena.lng ?
                          <MainMap
                            lat={data.arena.lat}
                            lng={data.arena.lng}
                            logo={data.arena.LOGOENCODED}
                            text={data.arena.DESCRIZIONE}
                            zoom={9}
                          />
                          :
                          <></>
                      }
                    </div>
                  </TabPanel>
                  <TabPanel secondHalf value={value} index={2}>
                    {
                      data.arena.GALLERYENCODED ?
                        <Gallery data={data.arena.GALLERYENCODED}/>
                        :
                        <div>Nessuna immagine</div>
                    }
                  </TabPanel>
                </Box>
              </Grid>
            </>
            : <></>
        }
      </Grid>
    </Container>
  )
}

export default PageArene