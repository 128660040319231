import { useCallback, useState } from "react"
import { TextField, FormControl, FormHelperText } from "@mui/material"

const ImageUploader = ({ text, onSubmit, onChange, onError, ...others }) => {
  // const [image, setImage] = useState(null)
  // const [imageUrl, setImageUrl] = useState(null)
  const [imageError, setImageError] = useState(text || "")

  const handleChange = useCallback((event) => {
    const file = event.target.files[0]
    if (!file) {
      // setImage(null)
      // setImageUrl("")
      onError && onError({ field: setImageError })
      return
    }

    // Convert the image to base64 encoding
    const reader = new FileReader()
    reader.onload = ({ target }) => {
      // setImageUrl(target.result)
      // setImage(file)
      onChange && onChange({ imageUrl: reader.result, image: file })
    }

    reader.readAsDataURL(file)
  }, [onChange, onError])

  return (
    <FormControl {...others}>
      <TextField
        name="AVATAR"
        inputProps={{ accept: "image/*" }}
        type="file"
        accept="image/*"
        onChange={handleChange}
      />
      {
        imageError &&
        (<FormHelperText>{imageError}</FormHelperText>)
      }
    </FormControl>
  )
}

export default ImageUploader
