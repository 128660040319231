import { Box, Typography, Modal } from "@mui/material"
import Document from "../DocumentsPages/Document"

import { DOCUMENT_ID } from "../DocumentsPages/useDocument"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4
}

const DocumentModal = ({ open, handleClose }) => {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2">
          Scarico responsabilità
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Document isGlassMorphism={false} style={{ width: "100%" }} documentId={DOCUMENT_ID.SCARICORESPONSABILITA}/>
        </Box>
      </Box>
    </Modal>
  )
}

export default DocumentModal


