import { createTheme } from "@mui/material/styles"
import { primaryColor } from "../components/Utils/constants"

const black = "#000000"
const colorTxt = primaryColor.hex

const theme = createTheme({
  palette: {
    mode: "dark",
    common: {
      black
    },
    text: {
      primary: colorTxt
    },
    base: {
      opacityGrey: "#b8b8b847"
    }
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          backgroundColor: black,
          borderBottom: "2px solid #1edcff"
        }
      }
    },
    MuiTable: {
      defaultProps: {
        sx: {
          backgroundColor: "#12121200",
          color: "#ffffffb3"
        }
      }
    },
    MuiTableBody: {
      defaultProps: {
        sx: {
          backgroundColor: "#12121200",
          color: "#ffffffb3"
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        sx: {
          backgroundColor: "#12121287",
          color: "#ffffffb3"
        }
      }
    },
    MuiLink: {
      defaultProps: {
        sx: {
          color: colorTxt
        }
      }
    }
  }
})

export default theme