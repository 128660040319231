import { useReducer, useState } from "react"
// import { useNavigate } from "react-router-dom"

import { simpleReducer } from "../Signup/signupUtils"
import { validateEmail } from "../Utils/validators"

import { Grid, Container, Typography, Button, TextField } from "@mui/material"
import Alert from "../Alert"
import { callProcessPath, dbName } from "../Utils/constants"

const ResetPassword = () => {
  // const navigate = useNavigate()

  const [alert, dispatchAlert] = useReducer(
    simpleReducer,
    {
      visibility: false,
      isError: true,
      message: ""
    }
  )

  const [email, setEmail] = useState("")

  const handleSubmit = () => {
    const data = { EMAIL: email }
    const url = callProcessPath + "?format=JSON&username=WEBPUBLIC&password=WEBPUBLIC&dbname=" + dbName + "&language=&processid=app_resetpasswordrequest"

    if (!email || !validateEmail(email)) {
      dispatchAlert({ key: "message", value: "Verificare la correttezza dell'email" })
      dispatchAlert({ key: "visibility", value: true })
      return
    }

    dispatchAlert({ key: "message", value: "Loading" })
    dispatchAlert({ key: "isError", value: false })
    dispatchAlert({ key: "visibility", value: true })

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (!response.fail) {
          dispatchAlert({
            key: "message",
            value: "La password per il recupero password è stata inviata con successo alla mail indicata"
          })
          dispatchAlert({ key: "isError", value: false })
          dispatchAlert({ key: "visibility", value: true })
        } else {
          throw new Error(response.message)
        }

        // setTimeout(() => {
        //   navigate("/" + response.redirectTo)
        // }, 2000)

      })
      .catch(error => {
        dispatchAlert({ key: "isError", value: true })
        dispatchAlert({ key: "message", value: error.message || "Si è verificato un errore, riprova" })
        dispatchAlert({ key: "visibility", value: true })

        // eslint-disable-next-line no-console
        console.error("Error:", error)
      })
  }

  return (
    <>
      <Container
        className="form__container"
        maxWidth="md"
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          justify="center"
          alignItems="center"
          className="glassmorphism"
          sx={{ padding: "3% 0", width: "fit-content", margin: "auto" }}
        >
          <Grid item xs={12}>
            <Typography marginLeft="2%" variant="h3" gutterBottom>
              Recupero password
            </Typography>
          </Grid>
          <Grid item xs={12} className="form__item-container">
            <TextField
              required
              className="form__input"
              id="email"
              label="Email"
              name="EMAIL"
              type="email"
              onChange={(event) => {
                setEmail(event.target.value)
              }}
              helperText="Inserire la email"
              error={false}
            />
          </Grid>
          <Grid item xs={12} className="form__item-container">
            <Button
              variant="outlined"
              size="large"
              onClick={handleSubmit}
            >
              Invia Mail
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Alert alert={alert}/>
    </>
  )
}

export default ResetPassword
