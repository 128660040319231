import { useState, useReducer, useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"

import {
  Box, TextField, Grid, CircularProgress, Button,
  InputLabel, FormControl, Select, MenuItem
} from "@mui/material"

import Alert from "../Alert"
import ImageUploader from "../ImageUploader"

import { simpleReducer } from "../Signup/signupUtils"
import { callProcess } from "../Utils/utils"
import { callProcessPath, dbName } from "../Utils/constants"

const UserSettings = () => {

  const params = useParams()

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  const [loading, setLoading] = useState(true)
  const [disabled, setDisabled] = useState(true)

  const [alert, dispatchAlert] = useReducer(
    simpleReducer,
    {
      visibility: false,
      isError: true,
      message: ""
    }
  )

  const initialData = {
    NOME: null,
    COGNOME: null,
    USERNAME: null,
    DISPLAY_NAME: null,
    EMAIL: null,
    INDIRIZZO: null,
    DATANASCITA: null,
    RFID: null,
    CAP: null,
    CITTA: null,
    PROVINCIA: null,
    NAZIONE: null,
    TELEFONO: null,
    AVATAR_ENCODED: null,
    IMMAGINE: null,
    UPLOADEDFILENAME: null,
    CRM_SESSO: ""
  }

  const [formInput, setFormInput] = useReducer(
    (state, newState) => {
      if (disabled) {
        setDisabled(false)
      }

      return ({
        ...state,
        ...newState
      })
    },
    initialData
  )

  // const [lateFormInput, setLateFormInput] = useState(initialData)

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&playerID=" + params.playerID + "&language=&processid=app_userdata"

    callProcess({ username, password, playerID: params.playerID, processid: "app_userdata" })
      // .then(response => response.json())
      .then((res) => {
        const formData = {
          NOME: res.useranagrafica.NOME || "",
          COGNOME: res.useranagrafica.COGNOME || "",
          USERNAME: res.userdata.LOGIN || "",
          DISPLAY_NAME: res.useranagrafica.DISPLAY_NAME || "",
          EMAIL: res.useranagrafica.USERS_EMAIL || "",
          INDIRIZZO: res.useranagrafica.INDIRIZZO || "",
          DATANASCITA: res.useranagrafica.DATANASCITA || "",
          RFID: res.useranagrafica.RFID || "",
          CAP: res.useranagrafica.CAP || "",
          CITTA: res.useranagrafica.CITTA || "",
          PROVINCIA: res.useranagrafica.PROVINCIA || "",
          NAZIONE: res.useranagrafica.NAZIONE || "",
          TELEFONO: res.useranagrafica.TELEFONO || "",
          AVATAR_ENCODED: res.AVATAR_ENCODED || "",
          UPLOADEDFILENAME: res.UPLOADEDFILENAME || "",
          CRM_SESSO: res.useranagrafica.CRM_SESSO || "M"
        }

        setFormInput(formData)
        // setLateFormInput(formData)
        setLoading(false)

      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [username, password, params.playerID])

  const handleInput = evt => {
    const name = evt.target.name
    const newValue = evt.target.value
    setFormInput({ [name]: newValue })
  }

  const handleSubmit = () => {
    const data = formInput

    const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&language=&processid=app_userdataupdate"

    fetch(url, {
      method: "POST",
      // headers: {
      //   'Content-Type': 'application/json'
      // },
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          dispatchAlert({ key: "message", value: "Dati modificati correttamente" })
          dispatchAlert({ key: "isError", value: false })
          dispatchAlert({ key: "visibility", value: true })
          // setLateFormInput(formInput)
        } else {
          throw new Error(response.message)
        }

        setTimeout(() => {
          dispatchAlert({ key: "visibility", value: false })
        }, 3000)

      })
      .catch(error => {
        dispatchAlert({ key: "message", value: error.message || "Si è verificato un errore, riprova" })
        dispatchAlert({ key: "visibility", value: true })
        dispatchAlert({ key: "isError", value: true })

        setTimeout(() => {
          dispatchAlert({ key: "visibility", value: false })
        }, 3000)
        // eslint-disable-next-line no-console
        console.error("Error:", error)
      })
  }

  return (
    <>
      <Box
        component="form"
        noValidate
        autoComplete="off"
        sx={{ height: "100%", width: "100%", padding: "2%" }}
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          justify="center"
          alignItems="center">
          {
            loading ?
              <CircularProgress className="circular-progress--centered"/>
              :
              (
                <Grid
                  container
                  spacing={2}
                  rowSpacing={2}
                  justify="center"
                  alignItems="center"
                  sx={{ padding: "3% 0", height: "100%", width: "100%", margin: "auto" }}
                >
                  {
                    [
                      { key: "NOME", type: "text" },
                      { key: "COGNOME", type: "text" },
                      { key: "USERNAME", type: "text" },
                      { key: "DISPLAY_NAME", type: "text" },
                      { key: "EMAIL", type: "email" },
                      { divider: true },
                      { key: "INDIRIZZO", type: "text" },
                      { key: "CITTA", type: "text" },
                      { key: "CAP", type: "number" },
                      { key: "PROVINCIA", type: "text" },
                      { key: "NAZIONE", type: "text" },
                      { divider: true },
                      { key: "DATANASCITA", type: "date" },
                      { key: "TELEFONO", type: "number" },
                      { key: "RFID", type: "number", readOnly: true }
                    ].map(({ key, type, divider = false, readOnly = false }, index) => {
                      const label = (key || "").replace("CRM_", "").replace("_", " ")

                      return (
                        <Grid item xs={12} md={divider ? 12 : 4} lg={divider ? 12 : 4} className="form__item-container"
                          key={key || ("divider" + index)}>
                          {
                            divider ?
                              <hr style={{ width: "80%", margin: "2% auto" }}/>
                              :
                              <TextField
                                className="form__input"
                                key={index}
                                id={key}
                                name={key}
                                label={label}
                                type={type}
                                value={formInput[key]}
                                onChange={handleInput}
                                InputProps={{
                                  readOnly
                                }}
                                {...(type === "date" ? { InputLabelProps: { shrink: true } } : {})}
                              />
                          }
                        </Grid>
                      )
                    })
                  }
                  <Grid item xs={12} md={4} lg={4} className="form__item-container">
                    <FormControl fullWidth>
                      <InputLabel id="label-CRM_SESSO">Sesso</InputLabel>
                      <Select
                        className="form__input"
                        id="CRM_SESSO"
                        name="CRM_SESSO"
                        labelId="label-CRM_SESSO"
                        value={formInput.CRM_SESSO}
                        onChange={handleInput}
                      >
                        <MenuItem value={"M"}>Maschio</MenuItem>
                        <MenuItem value={"F"}>Femmina</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="form__item-container">
                    <hr style={{ width: "80%", margin: "2% auto" }}/>
                  </Grid>
                  <Grid item xs={12} md={12} lg={12} className="form__item-container" sx={{ flexDirection: "column" }}>
                    <InputLabel>Inserisci avatar</InputLabel>
                    <br/>
                    <ImageUploader
                      onChange={({ imageUrl, image }) => {
                        setFormInput({ AVATAR_ENCODED: imageUrl, UPLOADEDFILENAME: image.name })
                        setFormInput({ IMMAGINE: image })
                      }}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center"
                      }}/>
                  </Grid>
                </Grid>
              )
          }
          <Grid item xs={12} md={6} lg={6} className="form__item-container" sx={{ marginX: "auto" }}>
            <Button
              variant="text"
              sx={{ textDecoration: "underline" }}
              component={Link}
              to="/ModificaPassword"
            >Modifica Password</Button>
          </Grid>
          <Grid item xs={12} className="form__item-container">
            <Button
              variant="outlined"
              sx={{ marginY: "2%" }}
              size="large" onClick={handleSubmit}
              disabled={disabled}
            >Salva</Button>
          </Grid>
          <Grid item xs={12} className="form__item-container" sx={{ position: "relative" }}>
            <Alert alert={alert}/>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default UserSettings

