import LightGallery from "lightgallery/react"
import lgZoom from "lightgallery/plugins/zoom"
import lgThumbnail from "lightgallery/plugins/thumbnail"

import "lightgallery/css/lightgallery.css"
import "lightgallery/css/lg-zoom.css"
import "lightgallery/css/lg-thumbnail.css"

import "./CSS/gallery.css"

const Gallery = ({ data }) => {

  return (<div className="gallery__container">
    <LightGallery
      speed={500}
      plugins={[lgThumbnail, lgZoom]}
      elementClassNames="gallery navbar--custom"
    >
      {data
        .filter(item => item !== "data:image/ory;base64,")
        .map((item, index) => (<a href={item} key={index} className="gallery__item">
          <img className="gallery__image" src={item} alt=""/>
        </a>))}
    </LightGallery>
  </div>)
}

export default Gallery