import { useReducer, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

import { Grid, Box, Typography, Button, Container, TextField, Checkbox } from "@mui/material"
import Alert from "../Alert"
import DocumentModal from "./DocumentModal"

import { validateCF, validateEmail } from "../Utils/validators"
import { call, simpleReducer } from "./signupUtils"
import DatePicker from "./DatePicker"

const SignupForm = () => {
  const params = useParams()
  const navigate = useNavigate()
  const formRef = useRef(null)

  const [open, setOpen] = useState(false)

  const [birthDate, setBirthDate] = useReducer(simpleReducer, { date: null, isMinorenne: false })

  const [alert, dispatchAlert] = useReducer((state, args) => {

    if (args.key === "visibility" && args.value === true) {
      setTimeout(() => {
        dispatchAlert({ key: "visibility", value: false })
      }, 3050)
    }

    return simpleReducer(state, args)

  },
  {
    visibility: false,
    isError: false,
    message: ""
  })

  const [isErrorInput, dispatchIsErrorInput] = useReducer((...args) => {
    return simpleReducer(...args)
  },
  {
    NOME: false,
    COGNOME: false,
    DISPLAYNAME: false,
    PASSWORD_SITE: false,
    PASSWORDCONF: false,
    EMAIL: false,
    RFID: false,
    PRIVACY: false,
    MAGGIORENENOME: false,
    MAGGIORENECOGNOME: false,
    MAGGIORENECF: false,
    SCARICORESPONSABILITA: false
  })

  const handleSubmit = () => {
    if (alert.visibility) {
      dispatchAlert({ key: "message", value: "Loading..." })
      dispatchAlert({ key: "isError", value: false })
    }

    const formData = new FormData(formRef.current)
    const data = Object.fromEntries(formData)

    if (!birthDate.date) {
      dispatchAlert({ key: "message", value: "Inserisci la data di nascita" })
      dispatchAlert({ key: "isError", value: true })
      dispatchAlert({ key: "visibility", value: true })
      return
    }

    data.DATANASCITA = birthDate.date

    if (!("PRIVACY" in data)) {
      dispatchIsErrorInput({ key: "PRIVACY", value: true })
    }

    if (!("SCARICORESPONSABILITA" in data) && birthDate.isMinorenne) {
      dispatchIsErrorInput({ key: "SCARICORESPONSABILITA", value: true })
    }

    for (const key in data) {
      const value = data[key]

      if (!birthDate.isMinorenne && (["MAGGIORENENOME", "MAGGIORENECOGNOME", "MAGGIORENECF", "SCARICORESPONSABILITA"].includes(key))) {
        continue
      }

      if ((!value || value.length <= 3) && !["PRIVACY", "SCARICORESPONSABILITA", "RFID"].includes(key)) {
        dispatchIsErrorInput({ key, value: true })
        continue
      }

      let isInvalid = false

      switch (key) {
        case "EMAIL": {
          isInvalid = !validateEmail(value)
          break
        }

        case "PASSWORD_SITE":
        case "PASSWORDCONF": {
          if (value !== data["PASSWORD_SITE"] || value !== data["PASSWORDCONF"]) {
            isInvalid = true
            dispatchIsErrorInput({ key: "PASSWORD_SITE", value: true })
          }

          break
        }

        case "MAGGIORENECF": {
          isInvalid = !validateCF(value, data["MAGGIORENENOME"], data["MAGGIORENECOGNOME"], new Date(data["DATANASCITA"]))
          break
        }

        case "PRIVACY": {
          if (value !== "on") {
            isInvalid = true
          }

          break
        }

        case "SCARICORESPONSABILITA": {
          if (birthDate.isMinorenne && value !== "on") {
            isInvalid = true
          }

          break
        }

        case "RFID": {
          if (isNaN(+value)) {
            isInvalid = true
          }

          break
        }

        default:
          break
      }

      dispatchIsErrorInput({ key, value: isInvalid })
    }

    if (Object.values(isErrorInput).filter(item => item).length) {
      // eslint-disable-next-line no-console
      console.error("Invalid form", {
        data,
        isErrorInput,
        cond: Object.values(isErrorInput).filter(item => item).length
      })
      dispatchAlert({ key: "message", value: "Inserisci tutti i campi" })
      dispatchAlert({ key: "isError", value: true })
      dispatchAlert({ key: "visibility", value: true })
      return
    }

    call(data)
      .then(res => {
        dispatchAlert({ key: "isError", value: !res.status })

        if (!res.status) {
          // eslint-disable-next-line no-console
          console.error("ERROR", res)
          dispatchAlert({ key: "message", value: res.message[0].toUpperCase() + res.message.slice(1) })
          dispatchAlert({ key: "visibility", value: true })

        } else {
          dispatchAlert({ key: "message", value: "Registrazione completata" })
          dispatchAlert({ key: "visibility", value: true })

          setTimeout(() => {
            navigate("/" + res.redirectTo)
          }, 1000)
        }

      })
      .catch(err => {
        dispatchAlert({ key: "isError", value: true })
        dispatchAlert({ key: "message", value: err.message[0].toUpperCase() + err.message.slice(1) })
        dispatchAlert({ key: "visibility", value: true })

        // eslint-disable-next-line no-console
        console.error("ERROR", { err })
      })
  }

  const commonPros = {
    item: {
      item: true,
      xs: 12,
      md: 6,
      xl: 6,
      alignItems: "center",
      justifyContent: "center",
      display: "flex"
    },
    container: {
      container: true,
      spacing: 2,
      rowSpacing: 1,
      item: true,
      xs: 12,
      md: 12,
      xl: 12,
      alignItems: "center",
      justifyContent: "center"
    }
  }

  return (
    <>
      <Container
        maxWidth="md"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          borderRadius: "1%"
        }}
        style={{
          padding: "2%",
          maxHeight: "78vh",
          overflowY: "scroll",
          maxWidth: "90vw"
        }}
        className="glassmorphism"
      >
        <Box
          component="form"
          autoComplete="off"
          ref={formRef}
        >
          <Grid
            container
            spacing={2}
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography marginLeft="2%" variant="h3" gutterBottom>
                Registrazione
              </Typography>
            </Grid>
            <Grid {...(commonPros.container)}>
              {
                ["nome", "cognome"].map((field, i) => (
                  <Grid key={i} {...(commonPros.item)}>
                    <TextField
                      sx={{ width: "80%", margin: "auto" }}
                      id={field}
                      name={field.toUpperCase()}
                      label={field[0].toUpperCase() + field.slice(1)}
                      error={isErrorInput[field.toUpperCase()]}
                      required
                    />
                  </Grid>
                ))
              }
              <Grid  {...(commonPros.item)}>
                <TextField
                  sx={{ width: "80%", margin: "auto" }}
                  id="email"
                  name="EMAIL"
                  label="Email"
                  type="email"
                  inputMode="email"
                  error={isErrorInput.EMAIL}
                  required
                />
              </Grid>
              <Grid  {...(commonPros.item)}>
                <DatePicker
                  value={birthDate.date}
                  className={"form__input" + (isErrorInput.DATANASCITA ? " datepicker--error" : "")}
                  onChange={(date) => {
                    if (date === "Invalid Date") {
                      return
                    }

                    const today = new Date()
                    const [day, month, year] = date.split("-")

                    const isMinorenne = !!(today.getFullYear() - (+year) < 18 ||
                      (today.getFullYear() - (+year) === 18 && (today.getMonth() + 1) < (+month)) ||
                      (today.getFullYear() - (+year) === 18 && (today.getMonth() + 1) === (+month) && today.getDate() < (+day))
                    )

                    setBirthDate({ key: "date", value: date })
                    setBirthDate({ key: "isMinorenne", value: isMinorenne })
                  }}
                  onError={(error) => {
                    if (error) {
                      dispatchIsErrorInput({ key: "DATANASCITA", value: true })
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={12} xl={12}>
                <hr style={{ width: "80%", margin: "2% auto" }}/>
              </Grid>
            </Grid>
            <Grid  {...(commonPros.container)}>
              <Grid  {...(commonPros.item)}>
                <TextField
                  sx={{ width: "80%", margin: "auto" }}
                  id="displayname"
                  name="DISPLAYNAME"
                  label="Nickname"
                  type="text"
                  error={isErrorInput.DISPLAYNAME}
                  required
                />
              </Grid>
              <Grid  {...(commonPros.item)}>
                <TextField
                  sx={{ width: "80%", margin: "auto" }}
                  // disabled={!!params.rfid}
                  InputProps={{
                    readOnly: !!params.rfid
                  }}
                  id="rfid"
                  label="RFID"
                  name="RFID"
                  type="number"
                  datatype="number"
                  value={params.rfid}
                  error={isErrorInput.RFID}
                />
              </Grid>
              <Grid  {...(commonPros.item)}>
                <TextField
                  sx={{ width: "80%", margin: "auto" }}
                  id="password"
                  name="PASSWORD_SITE"
                  label="Password"
                  type="password"
                  error={isErrorInput.PASSWORD_SITE}
                  required
                />
              </Grid>
              <Grid  {...(commonPros.item)}>
                <TextField
                  sx={{ width: "80%", margin: "auto" }}
                  id="password-conf"
                  name="PASSWORDCONF"
                  label="Conferma Password"
                  type="password"
                  error={isErrorInput.PASSWORDCONF}
                  required
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Checkbox
                id="privacy"
                name="PRIVACY"
                className={isErrorInput.PRIVACY ? "checkbox--error" : ""}
              />
              <label>Privacy</label>
            </Grid>
            {
              birthDate.isMinorenne &&
              (
                <Grid {...(commonPros.container)}>
                  <Grid item xs={12}>
                    <Typography variant="h3" gutterBottom>
                      Dati Maggiorenne Rappresentante
                    </Typography>
                  </Grid>
                  <Grid {...(commonPros.container)}>
                    <Grid  {...(commonPros.item)}>
                      <TextField
                        sx={{ width: "80%", margin: "auto" }}
                        id="mag-nome"
                        label="Nome"
                        name="MAGGIORENENOME"
                        error={isErrorInput.MAGGIORENENOME}
                      />
                    </Grid>
                    <Grid  {...(commonPros.item)}>
                      <TextField
                        sx={{ width: "80%", margin: "auto" }}
                        id="mag-cognome"
                        label="Cognome"
                        name="MAGGIORENECOGNOME"
                        error={isErrorInput.MAGGIORENENOME}
                      />
                    </Grid>
                    <Grid  {...(commonPros.item)}>
                      <TextField
                        sx={{ width: "80%", margin: "auto" }}
                        id="mag-cf"
                        label="Codice Fiscale"
                        name="MAGGIORENECF"
                        error={isErrorInput.MAGGIORENECF}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      required
                      name="SCARICORESPONSABILITA"
                      className={isErrorInput.SCARICORESPONSABILITA ? "checkbox--error" : ""}
                    />
                    <label onClick={() => setOpen(true)}>Scarico di responsabilità minori</label>
                    <DocumentModal
                      open={open}
                      handleClose={() => {
                        setOpen(false)
                      }}
                    />
                  </Grid>
                </Grid>
              )
            }
            <Grid xs={12} item>
              <Typography component="p">
                Dopo aver premuto Registrati, controlla la mail qui indicata per confermare le tua registrazione
              </Typography>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="center"
              sx={{ margin: "2%" }}
              xs={12}
            >
              <Button
                variant="outlined"
                size="large"
                onClick={handleSubmit}
              >
                Registrati
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Alert alert={alert}/>
    </>
  )
}

export default SignupForm