import { Alert, Stack } from "@mui/material"

const CustomAlert = ({ alert: { visibility, message, isError } }) => {

  return (
    <>
      {
        visibility &&
        (
          <Stack spacing={2} className="alert__container">
            <Alert variant="filled" severity={isError ? "error" : "success"}>
              {message}
            </Alert>
          </Stack>
        )
      }
    </>
  )
}

export const initialState = {
  visibility: false,
  message: "",
  isError: false
}

export function alertReducer(_, { message, isError = false, visibility = true, resetter = null }) {
  if (resetter) {
    setTimeout(() => {
      resetter({
        visibility: false
      })
    }, 3000)
  }

  return ({
    visibility,
    isError,
    message
  })
}

export default CustomAlert