import { callProcessPath, dbName } from "./constants"

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`
  }
}

const dateFormatter = (date, onlyTime) => {
  const dateTime = new Date(date)
  let mm = dateTime.getMonth() + 1 // Months start at 0!
  let dd = dateTime.getDate()

  dd = dd < 10 ? "0" + dd : dd
  mm = mm < 10 ? "0" + mm : mm

  if (onlyTime) {
    return (`${dateTime.getHours()}:${dateTime.getMinutes()}`)
  }

  return (`${dd}/${mm}/${dateTime.getFullYear()}`)
}

const callProcess = async(
  {
    username = "WEBPUBLIC",
    password = "WEBPUBLIC",
    dbNameCustom = dbName,
    userId = "",
    processid = "",
    ID = "",
    filter = "",
    matchID = "",
    arenaID = "",
    rfid = "",
    playerID = "",
    documentID = "",
    token = ""
  }
) => {
  const url = `?format=JSON${token ? `&TOKEN=${token}` : ""}&username=${username}&password=${password}&dbname=${dbNameCustom}&ID=${ID}&userID=${userId}&language=${filter ? `&filter=${filter}` : ""}${matchID ? `&matchID=${matchID}` : ""}${arenaID ? `&arenaID=${arenaID}` : ""}${rfid ? `&rfid=${rfid}` : ""}${playerID ? `&playerID=${playerID}` : ""}${documentID ? `&documentID=${documentID}` : ""}&processid=${processid}`

  let response = await fetch(callProcessPath + url)
  if (!response.ok) {
    throw new Error("Data could not be fetched!")
  }

  response = await response.json()

  return (response)
}

const objectComparator = (a, b, property) => {
  if (b[property] < a[property]) {
    return -1
  }

  if (b[property] > a[property]) {
    return 1
  }

  return 0
}

/**
 * Funzione che riordina due oggetti
 * @param order tipo di ordine ("asc" o "desc")
 * @param orderBy
 * @returns {{(*, *): *, (*, *): number}}
 */
const orderBy = (order, orderBy) => {
  return (
    order === "desc"
      ? (a, b) => objectComparator(a, b, orderBy)
      : (a, b) => -objectComparator(a, b, orderBy)
  )
}

function stableSort(array,
  comparator) {
  const stabilizedThis = array.map((el, index) => [el, index])

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })

  return stabilizedThis.map((el) => el[0])
}

export {
  a11yProps,
  dateFormatter,
  callProcess,
  objectComparator,
  orderBy,
  stableSort
}