import { Link } from "react-router-dom"

import "./CSS/footer.css"

const Footer = ({ className, ...others }) => (
  <div
    {...others}
    className={"footer" + (className ? " " + className : "")}
  >
    <div style={{ color: "#BBB" }}>
      © 2016 - {(new Date()).getFullYear()} Next Technology -
      <Link className="link--no-color" to="/NoteLegali">Note legali e condizioni d’uso</Link> -
      <Link className="link--no-color" to="/PrivacyPolicy">Privacy and Cookies law</Link> -
      <Link className="link--no-color" to="/contactPage">Contattaci</Link>
    </div>
    <hr className="footer__separator"/>
    <div className="footer__icon-container">
      <img
        className="footer__icon"
        src={require("../assets/facebook.png")}
        onClick={() => window.open("https://www.facebook.com/XRaid.laserGame/", "_blank")}
        alt=""/>
      <img
        className="footer__icon"
        src={require("../assets/instagram.png")}
        onClick={() => window.open("https://www.instagram.com/xraid.lasertag/", "_blank")}
        alt=""/>
      <img
        className="footer__icon"
        src={require("../assets/youtube.png")}
        onClick={() => window.open("https://www.youtube.com/channel/UCvwR5scDnLqtpdR-Oynj2oA", "_blank")}
        alt=""/>
    </div>
  </div>
)

export default Footer