import { connect } from "react-redux"

import theme from "./theme/theme"
import { ThemeProvider } from "@mui/material/styles"

import { Routes, Route } from "react-router-dom"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import HeaderBar from "./components/Navbar"
import Home from "./components/Home"
import Login from "./components/Login"
import Footer from "./components/Footer"
import TopRanking from "./components/TopRanking/index"
import Modalita from "./components/Modalità"
import PageModalities from "./components/Modalità/pageModalities"
import Arene from "./components/Arene/index"
import PageArene from "./components/Arene/PageArene"
import PlayerProfilo from "./components/User/index"
import PlayerProfiloV2 from "./components/UserV2/index"
import RfidCmp from "./components/Rfid"
import ChangePassword from "./components/User/ChangePassword"
import ResetPassword from "./components/User/ResetPassword"
import SignupForm from "./components/Signup/SignupForm"
import ContactPage from "./components/ContactPage"
import HandleRefreshPassword from "./components/User/HandleRefreshPassword"
import Document from "./components/DocumentsPages/Document"
// import { Counter } from './features/counter/Counter'
// import MatchReport from "./components/User/MatchReport"

import "./components/CSS/forms.css"
import "./components/CSS/reset.css"
import "./components/CSS/tables.css"
import "./components/CSS/utils.css"
import { DOCUMENT_ID } from "./components/DocumentsPages/useDocument"
import Achievements from "./components/Achievements"

export const mapState = (state) => ({
  language: state.language,
  status: state.status,
  username: state.username,
  password: state.password,
  userID: state.userID,
  loginStatus: state.loginStatus,
  validationLogin: state.validationLogin,
  data: state.data
})

const connector = connect(mapState)

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ThemeProvider theme={theme}>
        <div className="App">
          <div className="header">
            <HeaderBar/>
          </div>
          <div className="body">
            <Routes>
              <Route path="" element={<Home/>}/>
              <Route path="/" element={<Home/>}/>
              <Route path="/Home" element={<Home/>}/>
              <Route path="/TopRanking" element={<TopRanking/>}/>
              <Route path="/Modalita" element={<Modalita/>}/>
              <Route path="/Modalita/:pageID" element={<PageModalities/>}/>
              {/* <Route path="/PageModalities/:pageID" element={<PageModalities/>}/>*/}
              <Route path="/Arene" element={<Arene/>}/>
              <Route path="/PageArene/:arenaID" element={<PageArene/>}/>
              <Route path="/Login" element={<Login/>}/>
              <Route path="/confirm/:token" element={<HandleRefreshPassword/>}/>
              <Route path="/restyle/Achievements" element={<Achievements/>}/>
              {/* <Route path="/Registrazione" element={<SignupForm/>} /> */}
              <Route path="/Registrazione/:rfid" element={<SignupForm/>}/>
              <Route path="/Profilo/:playerID" element={<PlayerProfilo/>}/>
              <Route path="/ProfiloV2/:playerID" element={<PlayerProfiloV2/>}/>
              {/* <Route path="/Profilo/Match/:matchID" element={<MatchReport/>}/> */}
              <Route path="/Rfid" element={<RfidCmp/>}/>
              <Route path="/Rfid/:rfid" element={<RfidCmp/>}/>
              <Route path="/ModificaPassword" element={<ChangePassword/>}/>
              <Route path="/ResetPassword" element={<ResetPassword/>}/>
              <Route path="/ContactPage" element={<ContactPage/>}/>
              <Route path="/PrivacyPolicy" element={<Document documentId={DOCUMENT_ID.PRIVACY}/>}/>
              <Route path="/NoteLegali" element={<Document documentId={DOCUMENT_ID.NOTE}/>}/>
              <Route path="*" element={<Login/>}/>
            </Routes>
          </div>
          <div className="footer__container">
            <Footer className="glassmorphism"/>
          </div>
        </div>
      </ThemeProvider>
    </LocalizationProvider>
  )
}

export default connector(App)
