import { Bar } from "react-chartjs-2"

const labels = ["January", "February", "March", "April", "May", "June", "July"]

const data = {
  labels, datasets: [{
    label: "Giocatore",
    data: "1 2 4 5 6 9 13 14 16 17 19 20".split(" "),
    borderColor: "rgb(255, 99, 132)",
    backgroundColor: "rgba(255, 99, 132, 0.5)",
    borderRadius: 10

  }, {
    label: "Punteggi globali",
    data: "-1 3 4 9 10 11 12 13 15 17 18 20".split(" "),
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.5)"
  }]
}

const Graph = () => {
  const options = {
    indexAxis: "x", scales: {
      y: {
        ticks: {
          crossAlign: "far"
        }
      }
    }, orientation: "vertical", // elements: {
    //   bar: {
    //     borderWidth: 2,
    //   },
    // },
    responsive: true // plugins: {
    //   legend: {
    //     position: "right",
    //   },
    //   title: {
    //     display: true,
    //     text: "Chart.js Horizontal Bar Chart",
    //   },
    // },
  }

  return <Bar options={options} data={data}/>
}

export default Graph
