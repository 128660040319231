// https://www.npmjs.com/package/google-map-react
// http://google-map-react.github.io/google-map-react/map/simple
import GoogleMap from "google-map-react"
import Geocode from "react-geocode"
import { primaryColor } from "./constants"

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyB8lxl0D4K5WCuudzpvx82qPymSNN-FiiA")

// set response language. Defaults to english.
Geocode.setLanguage("en")

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
Geocode.setRegion("it")

// Enable or disable logs. Its optional.
Geocode.enableDebug()

const MyGreatPlace = (props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      }}
      onClick={() => {
        window.open("https://www.google.com/maps/search/?api=1&query=" + props.lat + "," + props.lng, "_blank")
      }}
    >
      <img
        src={props.logo}
        style={{ width: "40px", position: "relative", borderRadius: "50%", border: `2px solid ${primaryColor.hex}` }}
        alt=""
      />
      <div className="glassmorphism" style={{ width: "max-content", borderRadius: "5px" }}>
        <p>{props.text}</p>
      </div>
    </div>
  )
}
const SimpleMapPage = ({ lng, lat, logo, zoom = 9, text = "Arena" }) => {
  lng = +lng
  lat = +lat

  return (
    <GoogleMap
      style={{ borderRadius: "15px", height: "100%", width: "100%" }}
      margin={["10px", "10px", "10px", "10px"]}
      apiKey="AIzaSyB8lxl0D4K5WCuudzpvx82qPymSNN-FiiA" // Set if you need stats etc ...
      bootstrapURLKeys={{ key: "AIzaSyB8lxl0D4K5WCuudzpvx82qPymSNN-FiiA", language: "en" }}
      center={[lat, lng]}
      zoom={zoom}
      yesIWantToUseGoogleMapApiInternals
    >
      <MyGreatPlace
        lat={lat}
        lng={lng}
        logo={logo}
        text={text}
      />
    </GoogleMap>
  )
}

export default SimpleMapPage