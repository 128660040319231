import { useReducer } from "react"
// import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { simpleReducer } from "../Signup/signupUtils"

import { Grid, Container, Typography, Button, TextField } from "@mui/material"
import Alert from "../Alert"
import { callProcessPath, dbName } from "../Utils/constants"
import { setUser } from "../../features/counter/counterSlice"

const ChangePassword = () => {

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const UserAnagraficaId = useSelector(state => state.counter.UserAnagraficaId)
  const avatar = useSelector(state => state.counter.userAvatar)
  const userName = useSelector(state => state.counter.userName)

  const dispatch = useDispatch()

  const [alert, dispatchAlert] = useReducer(
    simpleReducer,
    {
      visibility: false,
      isError: true,
      message: ""
    }
  )

  const [formInput, setFormInput] = useReducer(
    simpleReducer,
    {
      PASSWORD_SITE: "",
      PASSWORDCONF: ""
    }
  )

  const handleSubmit = () => {
    const data = formInput
    const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&language=&processid=app_userdataupdate"

    if (!data.PASSWORD_SITE || !data.PASSWORDCONF) {
      dispatchAlert({ key: "message", value: "Inserire password e conferma password" })
      dispatchAlert({ key: "visibility", value: true })
      return
    }

    if (data.PASSWORD_SITE !== data.PASSWORDCONF) {
      dispatchAlert({ key: "message", value: "Password e conferma password devono coincidere" })
      dispatchAlert({ key: "visibility", value: true })
      return
    }

    fetch(url, {
      method: "POST",
      body: JSON.stringify(data)
    })
      .then(response => response.json())
      .then(response => {
        if (response.success) {
          dispatchAlert({ key: "message", value: "La password è stata modificata correttamente" })
          dispatchAlert({ key: "isError", value: false })
          dispatchAlert({ key: "visibility", value: true })

          const updateArray = [
            username,
            response.FormTesta.PASSWORD_SITE,
            true,
            userName,
            UserAnagraficaId,
            avatar
          ]

          dispatch(setUser(updateArray))
        } else {
          throw new Error(response.message)
        }

        // setTimeout(() => {
        //   navigate("/" + response.redirectTo)
        // }, 2000)

      })
      .catch(error => {
        dispatchAlert({ key: "message", value: error.message || "Si è verificato un errore, riprova" })
        dispatchAlert({ key: "visibility", value: true })

        // eslint-disable-next-line no-console
        console.error("Error:", error)
      })
  }

  const handleInput = ({ target }) => {
    const key = target.name
    const value = target.value
    setFormInput({ key, value })
  }

  return (
    <>
      <Container
        className="form__container"
        maxWidth="md"
      >
        <Grid
          container
          spacing={2}
          rowSpacing={2}
          justify="center"
          alignItems="center"
          className="glassmorphism"
          sx={{ padding: "3% 0", width: "fit-content", margin: "auto" }}
        >
          <Grid item xs={12}>
            <Typography marginLeft="2%" variant="h3" gutterBottom>
              Modifica Password
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} xl={6} lg={6} className="form__item-container">
            <TextField
              id="password"
              label="Password"
              name="PASSWORD_SITE"
              type="password"
              autoComplete="current-password"
              className="form__input"
              onChange={handleInput}
              helperText="Inserire la nuova password"
              error={false}
            />
          </Grid>
          <Grid item xs={12} md={6} xl={6} lg={6} className="form__item-container">
            <TextField
              id="password-conf"
              label="Conferma Password"
              name="PASSWORDCONF"
              type="password"
              autoComplete="current-password"
              className="form__input"
              onChange={handleInput}
              helperText="Reinserisci la nuova password"
              error={false}
            />
          </Grid>
          <Grid xs={12} md={12} xl={12}>
            <hr style={{ width: "80%", margin: "2% auto" }}/>
          </Grid>
          <Grid item xs={12} className="form__item-container">
            <Button
              variant="outlined"
              size="large"
              onClick={handleSubmit}
            >
              MODIFICA PASSWORD
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Alert alert={alert}/>
    </>
  )
}

export default ChangePassword