import { useState } from "react"

import {
  Table,
  TableBody,
  TableCell,
  TableRow
} from "@mui/material"
import Head from "./Head"
import { Link } from "react-router-dom"

import { orderBy as orderByKey, stableSort } from "../../Utils/utils"

const CustomTable = ({ rows, activeMatchReport }) => {
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("date")

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc"
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
  }

  return (
    <Table stickyHeader>
      <Head
        className="table__custom--head__opaque"
        headCells={[
          { id: "gameMode", label: "Modalita", sortable: true },
          { id: "arena", label: "Arena", sortable: true },
          { id: "score", label: "Punteggio", sortable: true },
          { id: "date", label: "Data", sortable: true },
          { id: "time", label: "Ora" }
        ]}
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}/>
      <TableBody className="table__custom--body">
        {
          stableSort(rows, orderByKey(order, orderBy))
            .map((row, index) => (
              <TableRow
                className="table__custom--row"
                component={Link} // to={"/Profilo/Match/" + match.MATCH_ID + ""}
                onClick={() => {
                  activeMatchReport(row.matchId)
                }}
                key={row.date + "-" + index}
                sx={{ textDecoration: "none", cursor: "pointer" }}
              >
                <TableCell className="table__custom--cell__white" component="th" scope="row"
                  sx={{ alignItems: "center" }}>
                  {row.gameMode}
                </TableCell>
                <TableCell className="table__custom--cell__white" align="left">{row.arena}</TableCell>
                <TableCell className="table__custom--cell__white" align="left">{row.score}</TableCell>
                <TableCell className="table__custom--cell__white" align="left">{row.date}</TableCell>
                <TableCell className="table__custom--cell__white" align="left">{row.time}</TableCell>
              </TableRow>
            ))}
      </TableBody>
    </Table>
  )
}

export default CustomTable