import useDocument from "./useDocument"
import { CircularProgress } from "@mui/material"

const Document = ({ documentId, style, isGlassMorphism = true, ...others }) => {
  const { loading, document, error } = useDocument(documentId)

  return (
    <div
      className={(isGlassMorphism ? "glassmorphism " : "") + "container--scrollable"}
      style={{ color: "white", width: "80%", margin: "auto", display: "flex", padding: "2%", ...style }}
      {...others}
    >
      {
        loading ?
          (<CircularProgress className="circular-progress--centered"/>) :
          error ?
            (<div>{error}</div>) :
            (<div dangerouslySetInnerHTML={{ __html: document }}/>)
      }
    </div>
  )
}

export default Document