import { useEffect } from "react"

const useMouseAnimation = (elementRef) => {
  useEffect(() => {
    let element = elementRef?.current

    if (element && !element.classList.contains("shiny")) {
      element.classList.add("shiny")
    }

    if (!element) {
      element = [...document.querySelectorAll(".shiny")]
    }


    const handleMouseMove = (event, element) => {
      const { x, y } = element.getBoundingClientRect()
      element.style.setProperty("--x", `${event.clientX - x}`)
      element.style.setProperty("--y", `${event.clientY - y}`)
    }

    if (Array.isArray(element)) {
      element.forEach((el) => {
        el.addEventListener("mousemove", (event) => handleMouseMove(event, el))
      })
    } else {
      element.addEventListener("mousemove", (event) => handleMouseMove(event, element))
    }

    return () => element.removeEventListener("mousemove", handleMouseMove)

  }, [elementRef])
}

export default useMouseAnimation

