import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import {
  Container, Paper, CircularProgress,
  TableContainer
} from "@mui/material"

import { callProcess, dateFormatter } from "../Utils/utils"
import Table from "./SortableTable/Table"

const MatchesTable = ({ activeMatchReport }) => {
  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  const [data, setData] = useState({
    data: [],
    isLoading: true
  })

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&matchID=&dbname=" + dbName + "&language=&processid=app_usermatchs"
    callProcess({ username, password, processid: "app_usermatchs" })
      .then((res) => {
        setData({ data: res, isLoading: false })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [username, password])

  return (
    <Container sx={{ padding: 0, width: "100%", maxWidth: "80vw", display: "flex" }}>
      {
        data.isLoading ?
          <CircularProgress className="circular-progress--centered"/> :
          <TableContainer component={Paper} sx={{ width: "100%" }} className="container--scrollable">
            <Table
              activeMatchReport={activeMatchReport}
              rows={
                data.data.matchs && data.data.matchs.map((match) => ({
                  gameMode: match.GAME_MODE,
                  arena: match.ARENA_NAME,
                  score: match.SCORES,
                  date: dateFormatter(match.START_TIME),
                  time: dateFormatter(match.START_TIME, true),
                  matchId: match.MATCH_ID
                })
                )
              }/>
          </TableContainer>
      }
    </Container>
  )

}
// return (
//   <Container sx={{ padding: 0, width: "100%", maxWidth: "80vw" }}>
//     <TableContainer component={Paper} sx={{ width: "100%" }} className="container--scrollable">
//       <Table stickyHeader>
//         <TableHead className="table__custom--head__opaque">
//           <TableRow>
//             <TableCell align="start" className="table__custom--cell">Modalita</TableCell>
//             <TableCell align="start" className="table__custom--cell">Arena</TableCell>
//             <TableCell align="start" className="table__custom--cell">Punteggio</TableCell>
//             <TableCell align="start" className="table__custom--cell">Data</TableCell>
//             <TableCell align="start" className="table__custom--cell">Ora</TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody className="table__custom--body">
//           {
//             data.isLoading ?
//               <TableRow className="table__custom--row"
//                 sx={{ width: "410%", display: "flex", justifyContent: "center" }}>
//                 <CircularProgress/>
//               </TableRow>
//               :
//               data.data.matchs && data.data.matchs.map((match, index) => {
//                 return (
//                   <TableRow
//                     className="table__custom--row"
//                     component={Link} //to={"/Profilo/Match/" + match.MATCH_ID + ""}
//                     onClick={() => {
//                       activeMatchReport(match.MATCH_ID)
//                     }}
//                     key={"match-" + index}
//                     sx={{ textDecoration: "none", cursor: "pointer" }}
//                   >
//                     <TableCell className="table__custom--cell__white" scope="row"
//                       sx={{ alignItems: "center" }}>{match.GAME_MODE}</TableCell>
//                     <TableCell className="table__custom--cell__white" align="start">{match.ARENA_NAME}</TableCell>
//                     <TableCell className="table__custom--cell__white" align="start">{match.SCORES}</TableCell>
//                     <TableCell className="table__custom--cell__white"
//                       align="start">{dateFormatter(match.START_TIME)}</TableCell>
//                     <TableCell className="table__custom--cell__white"
//                       align="start">{dateFormatter(match.START_TIME, true)}</TableCell>
//                   </TableRow>
//                 )
//               })
//           }
//         </TableBody>
//       </Table>
//     </TableContainer>
//   </Container>
// )

export default MatchesTable