import { Radar } from "react-chartjs-2"

import { primaryColor } from "../Utils/constants"

const StatsGraph = ({ data }) => {

  const labels = ["Media Uccisioni", "Media Colpi", "Precisione", "Media Obbiettivi", "Punteggio Medio"]

  const globalColor = "194, 149, 27"// "236, 79, 79"

  const { global_average_data: globalData = null, global_user_games_data: userData = null } = data

  const radarData = {
    labels,
    datasets: [
      {
        label: data.DISPLAY_NAME,
        backgroundColor: `rgba(${globalColor}, .5)`,
        borderColor: `rgba(${globalColor}, .8)`,
        pointBackgroundColor: `rgba(${globalColor}, .8)`,
        pointBorderColor: "#FFF",
        pointHoverBackgroundColor: "#FFF",
        pointHoverBorderColor: `rgba(${globalColor}, 1)`,
        data: [
          Math.log((userData.global_kills || 0) / globalData.average_global_tot_kills),
          Math.log((userData.global_totshots || 0) / globalData.average_global_totshots),
          Math.log((userData.global_accuarcy || 0) / globalData.average_global_accuarcy),
          Math.log((userData.global_capture || 0) / globalData.average_global_captures),
          Math.log((userData.global_score || 0) / globalData.average_global_score)
        ]
      },
      {
        label: "GLOBALE",
        backgroundColor: `rgba(${primaryColor.rgb} , .5)`, // `rgba(${globalColor}, .5)`, //"#EC4F4F88",
        borderColor: `rgba(${primaryColor.rgb} , .8)`, // `rgba(${globalColor}, .8)`, // rgba(236, 79, 79, .8)
        pointBackgroundColor: `rgba(${primaryColor.rgb} , .8)`, // `rgba(${globalColor}, .8)`,
        pointBorderColor: "#FFF", // "#EC4F4F",
        pointHoverBackgroundColor: "#FFF",
        pointHoverBorderColor: `rgba(${primaryColor.rgb} , 1)`, // `rgba(${globalColor}, 1)`,
        data: [
          Math.log(globalData.average_global_tot_kills / (userData.global_kills || 1)),
          Math.log(globalData.average_global_totshots / (userData.global_totshots || 1)),
          Math.log(globalData.average_global_accuarcy / (userData.global_accuarcy || 1)),
          Math.log(globalData.average_global_captures / (userData.global_captures || 1)),
          Math.log(globalData.average_global_score / (userData.global_score || 1))
        ]
        // pointBorderWidth: 2,
        // pointRadius: 6,
      }
    ]
  }

  const options = {
    // responsive: true,
    scale: {
      ticks: {
        // min: 0,
        // max: 100000000,
        // stepSize: 10000,
        showLabelBackdrop: true,
        backdropColor: "#FFF"
      },
      angleLines: {
        color: "#FFF",
        lineWidth: 1
      },
      pointLabels: {
        color: "#FFF"
      },
      gridLines: {
        color: "#FFF" // "rgba(194, 149, 27)"
        // circular: true
      }
    }
  }
  // eslint-disable-next-line no-console
  console.debug("radarData", { userData, globalData })

  return (
    <Radar
      // ref={this.chartRef}
      data={radarData}
      options={options}
    />
  )
}

export default StatsGraph