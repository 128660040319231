import { useReducer, useState } from "react"
import { useNavigate } from "react-router-dom"

import { Button, Container, Grid, TextField, Typography } from "@mui/material"
import Alert from "../Alert"

import { callProcessPath } from "../Utils/constants"
import { validateEmail } from "../Utils/validators"

const ContactPage = () => {

  const navigate = useNavigate()

  const [formData, setFormData] = useState({
    NOME: "",
    COGNOME: "",
    EMAIL: "",
    DESCRIZIONE: ""
  })

  const [alert, dispatchAlert] = useReducer((state, newState) => {
    return (
      Object.entries(newState).reduce((acc, [newStateKey, newStateValue]) => {
        acc[newStateKey] = newStateValue
        return acc
      }, { visibility: true })
    )
  },
  {
    visibility: false,
    isError: false,
    message: ""
  }
  )

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setFormData((prevState) => ({ ...prevState, [name]: value }))
  }

  const handleSubmit = async(event) => {
    event.preventDefault()

    if (!validateEmail(formData.EMAIL || "") || formData.NOME === "" || formData.COGNOME === "" || formData.DESCRIZIONE === "") {
      dispatchAlert({ isError: true, message: "Compila tutti i campi" })
      return
    }

    dispatchAlert({ visibility: true, isError: false, message: "Caricamento..." })

    const url = callProcessPath + "?format=JSON&username=WEBPUBLIC&password=WEBPUBLIC&dbname=dev&language=&processid=app_contattami"
    let res = await fetch(url, {
      method: "POST",
      body: JSON.stringify(formData)
    })

    res = await res.json()

    if (res.success) {
      dispatchAlert({ visibility: true, isError: false, message: "Messaggio inviato con successo" })

      setFormData({
        NOME: "",
        COGNOME: "",
        EMAIL: "",
        DESCRIZIONE: ""
      })

      setTimeout(() => {
        navigate("/")
      }, 1500)

    } else {
      dispatchAlert({ visibility: true, isError: false, message: "Non è stato possibile inviare il messagio" })
    }
  }

  return (
    <>
      <Container
        maxWidth="md"
        style={{
          borderRadius: "1%",
          marginTop: "2%",
          padding: "3%",
          maxHeight: "78vh",
          overflowY: "scroll",
          maxWidth: "85vw"
        }}
        className="glassmorphism"
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Contatti
        </Typography>
        <Grid
          container
          component="form"
          onSubmit={handleSubmit}
          gap={2}
          sx={{
            padding: "2%", margin: "auto",
            justifyContent: "center"
          }}
        >
          <Grid xs={12} md={5} lg={5} item className="form__item-container">
            <TextField
              fullWidth
              required
              margin="normal"
              label="Nome"
              name="NOME"
              value={formData.NOME}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid xs={12} md={5} lg={5} item className="form__item-container">
            <TextField
              fullWidth
              required
              margin="normal"
              label="Cognome"
              name="COGNOME"
              value={formData.COGNOME}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid xs={12} md={6} lg={6} item className="form__item-container">
            <TextField
              fullWidth
              required
              margin="normal"
              label="Email"
              name="EMAIL"
              type="email"
              value={formData.EMAIL}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid xs={12} md={10} lg={10} item className="form__item-container">
            <TextField
              fullWidth
              required
              margin="normal"
              multiline
              rows={4}
              label="Descrizione"
              name="DESCRIZIONE"
              value={formData.DESCRIZIONE}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid xs={12} md={10} lg={10} item className="form__item-container">
            <Button type="submit" variant="contained" color="primary">
              Invia
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Alert alert={alert}/>
    </>
  )
}

export default ContactPage