import { Box, Typography } from "@mui/material"
import PropTypes from "prop-types"

const TabPanel = (props) => {
  const { children, value, index, secondHalf, sx, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "100%", width: "100%" }}
      {...other}
    >
      {
        secondHalf ? (
          <Box sx={{ p: 3, height: "100%", width: "100%", ...sx }}>
            {children}
          </Box>
        ) :
          value === index && (
            <Box sx={{ p: 3, height: "100%", width: "100%", ...sx }}>
              <Typography>{children}</Typography>
            </Box>)
      }
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
}

export default TabPanel