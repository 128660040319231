import { useCallback, useEffect, useReducer, useState } from "react"
import { useSelector } from "react-redux"
import { useParams, useSearchParams } from "react-router-dom"

import {
  Container, Divider, Avatar,
  Tabs, Tab, Box, Grid
} from "@mui/material"

import { callProcessPath, imageBasePath, dbName } from "../Utils/constants"
import { a11yProps } from "../Utils/utils"

import UserSettings from "../User/UserSettings"
import MatchesTable from "../User/MatchesTable"
import MatchReport from "../User/MatchReport"
import Statistics from "./Statistics"
import TabPanel from "../TabPanel"

import "../CSS/user.css"
// import StatsGraph from "../User/StatsGraph"
import Alert, { alertReducer, initialState } from "../Alert"

const Profile = () => {
  const params = useParams()

  const [searchParams] = useSearchParams()
  const [alertData, setAlertData] = useReducer(alertReducer, initialState)

  const [value, setValue] = useState(searchParams.get("action") === "edit" ? 2 : 0)
  const [currMatch, setCurrMatch] = useState(0)

  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)

  const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&playerID=" + params.playerID + "&language=&processid=app_userdata"

  const [data, setData] = useState([])

  const fetchData = useCallback(async() => {
    const response = await fetch(url)
    if (!response.ok) {
      throw new Error("Data coud not be fetched!")
    } else {
      return response.json()
    }
  }, [url])

  useEffect(() => {
    fetchData()
      .then((res) => {
        setData(res)
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [fetchData])

  useEffect(() => {
    if (searchParams.get("action") === "edit") {
      setAlertData({ message: "Perfavore compila i campi del tuo profilo", resetter: setAlertData })
    }

  }, [searchParams])

  // data && data.global_user_games_data && data.global_average_data ?
  //   console.log("DATI!", data.global_average_data.average_global_tot_kills, data.global_average_data.average_global_totshots, data.global_average_data.average_global_accuarcy, data.global_average_data.average_global_captures, data.global_average_data.average_global_score)
  //   :
  //   console.log("niente")

  return (
    <>
      <Container sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          sx={{ width: "100%", margin: "auto", padding: "3%", flexDirection: "row !important" }}
          style={{ gap: "2%", rowGap: "1vh" }}
        >
          <Grid
            item
            xs={12} md={4} lg={4}
            className="glassmorphism"
            sx={{ padding: "3%" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
              <Avatar sx={{ width: "70px", height: "70px" }} src={imageBasePath + data.AVATAR}
                alt={data.AVATAR}/>
              {/* <ListItem>*/}
              {/*  <ListItemText*/}
              {/*    primary={data.DISPLAY_NAME}*/}
              {/*    secondary={*/}
              {/*      <div>*/}
              {/*        <p>Arena di provenienza: {data.ARENA_ORIGIN_NAME}</p>*/}
              {/*      </div>*/}
              {/*    }*/}
              {/*  />*/}
              {/* </ListItem>*/}
            </Box>
            <br/>
            <br/>
            <Divider/>
            <Tabs variant="fullWidth" value={value} onChange={(_, newValue) => setValue(newValue)}
              sx={{ display: "flex" }}>
              <Tab label="Radar" {...a11yProps(0)} />
              {data.useranagrafica ? <Tab label="Partite" {...a11yProps(1)}/> : <div></div>}
              {data.useranagrafica ? <Tab label="Modifica Profilo" {...a11yProps(2)}/> : <div></div>}
            </Tabs>
          </Grid>
          <Grid item xs={12} md={7} lg={7} className="glassmorphism">
            <TabPanel secondHalf value={value} index={0} style={{ height: "100%", width: "100%" }}>
              <Statistics data={data}/>
            </TabPanel>
            <TabPanel secondHalf value={value} index={1}>
              {
                username !== "WEBPUBLIC" ?
                  <MatchesTable
                    activeMatchReport={(matchID) => {
                      setCurrMatch(matchID)
                      setValue(3)
                    }}
                  />
                  : <></>
              }
            </TabPanel>
            <TabPanel secondHalf value={value} index={2}>
              <UserSettings/>
            </TabPanel>
            <TabPanel secondHalf value={value} index={3}>
              {
                username !== "WEBPUBLIC" ?
                  <MatchReport currMatchID={currMatch}/>
                  : <></>
              }
            </TabPanel>
          </Grid>
        </Grid>
      </Container>
      <Alert alert={alertData}/>
    </>
  )
}

export default Profile