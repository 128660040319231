// import CountUp from "react-countup"
import { Box, Container } from "@mui/material"
import { styled } from "@mui/material"

import logo from "../assets/Logo-XRAID-r.png"

import "./CSS/home.css"

const Logo = styled("img")({
  width: "100%", // height: "20vh",
  display: "flex", margin: "auto"
})

const Home = () => {

  return (
    <Container maxWidth="lg">
      <Box sx={{ marginLeft: "auto", marginRight: "auto", width: "100%", padding: "5%" }}>
        <Logo sx={{ width: "100%" }} src={logo} alt="X-Raid Logo"/>
        <br></br>
        Benvenuto nel Portale Giocatori XRaid Lasergame
        Qui puoi vedere i tuoi punteggi, condividere il tuo profilo, acquisire esperienza e
        diventare il miglior giocatore di Laser Game del mondo
        Registrati e gioca in tutte le arene XRaid Lasergame
        con la tua Membership Card personale
        <Box
          sx={{ display: "flex", alignItems: "center", marginTop: "10%", fontSize: "3rem", justifyContent: "center" }}>
          {/* Button:*/}
          {/* <InstallPWAButton/>*/}
          {/* <Avatar variant="square" src={require("../assets/line-chart.png")}/>*/}
          {/* <CountUp end={37894} className="statistic"/>*/}
        </Box>
      </Box>
    </Container>
  )
}

export default Home