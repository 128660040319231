import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { Link } from "react-router-dom"
import {
  Paper, Avatar, Container, CircularProgress,
  TableContainer, Table, TableBody, TableRow, TableCell, Box
} from "@mui/material"

import { callProcess } from "../Utils/utils"

import "../CSS/topRanking-arene.css"

const Arene = () => {
  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const userId = useSelector(state => state.counter.userId)

  const [data, setData] = useState({
    data: [],
    isLoading: true
  })

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&ID=&userID=" + userId + "&language=&processid=app_arenas"
    callProcess({ username, password, userId, processid: "app_arenas" })
      .then((res) => {
        setData({ data: res, isLoading: false })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [password, userId, username])

  return (
    <Container className="arene__container">
      <Box className="glassmorphism arene__box">
        {
          data.isLoading ?
            <div style={{ alignItems: "center", justifyContent: "center", display: "flex", height: "inherit" }}>
              <CircularProgress className="circular-progress--centered"/>
            </div>
            :
            <TableContainer component={Paper} className="container--scrollable" sx={{ maxWidth: "90vw", margin: "auto" }}>
              <Table stickyHeader sx={{ padding: "2%" }}>
                <TableBody className="table__custom--body">
                  {
                    data.data.arenas && data.data.arenas.map((arena, index) => {
                      return (
                        <TableRow
                          className="table__custom--row"
                          // component={Link}
                          // to={"/pageArene/" + arena.ID + ""}
                          key={index}
                          sx={{ textDecoration: "none" }}>
                          <TableCell
                            className="table__custom--cell__white"
                            component="th"
                            scope="row"
                          >
                            <Link to={"/pageArene/" + arena.ID + ""}>
                              <Avatar src={arena.LOGOENCODED} alt={arena.LOGO} sx={{ margin: "0 auto" }} variant="square"/>
                            </Link>
                          </TableCell>
                          <TableCell className="table__custom--cell__white" align="left">
                            <Link className="link--no-color" to={"/pageArene/" + arena.ID + ""}>
                              {arena.DESCRIZIONE}
                            </Link>
                          </TableCell>
                          <TableCell className="table__custom--cell__white" align="left">
                            <Link className="link--no-color" to={"/pageArene/" + arena.ID + ""}>
                              {arena.CITTA}
                            </Link>
                          </TableCell>
                          <TableCell className="table__custom--cell__white" align="left" sx={{ textTransform: "uppercase" }}>
                            <Link className="link--no-color" to={"/pageArene/" + arena.ID + ""}>
                              {arena.PROVINCIA}
                            </Link>
                          </TableCell>
                          <TableCell className="table__custom--cell__white" align="left" sx={{ textTransform: "uppercase" }}>
                            <Link className="link--no-color" to={"/pageArene/" + arena.ID + ""}>
                              {arena.NAZIONE}
                            </Link>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  }
                </TableBody>
              </Table>
            </TableContainer>
        }
      </Box>
    </Container>
  )
}

export default Arene

