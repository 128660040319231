const validateCF = (taxCode, name, surname, dateOfBirth) => {
  // Check that the tax code has exactly 16 characters
  return (taxCode.length === 16)
}
const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gm
  return (re.test(String(email).toLowerCase()))
}

module.exports = {
  validateCF,
  validateEmail
}