import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUser } from "../../features/counter/counterSlice"
import { useNavigate } from "react-router-dom"

import {
  AppBar, Button, IconButton, SwipeableDrawer, List,
  ListItem, Hidden, Divider, Avatar, Box, MenuItem, CssBaseline, ListItemText, Toolbar, Grid
} from "@mui/material"

import MenuIcon from "@mui/icons-material/Menu"

import Nav from "./Nav"
import StyledMenu from "./StyledMenu"
import { Link } from "react-router-dom"

import logo from "../../assets/Logo-XRAID-r.png"
import { imageBasePath } from "../Utils/constants"

import "../CSS/navbar.css"

const HeaderBar = () => {

  const [open, setOpen] = useState(false)
  const [tab, setTab] = useState(2)

  const handleTabChange = (newValue) => {
    // eslint-disable-next-line no-console
    console.debug("[DEBUG] HANDLETAB: ", newValue)
    setTab(newValue)
    setOpen(false)
  }

  const validationLogin = useSelector(state => state.counter.validationLogin)
  const userName = useSelector(state => state.counter.userName)
  const UserAnagraficaId = useSelector(state => state.counter.UserAnagraficaId)
  const userAvatar = useSelector(state => state.counter.userAvatar)

  const [anchorEl, setAnchorEl] = useState(null)

  const openMenu = Boolean(anchorEl)

  const handleClose = () => {
    // eslint-disable-next-line no-console
    console.debug("[DEBUG] handleClose CLICKED", anchorEl)
    setAnchorEl(null)
  }

  const dispatch = useDispatch()

  const Logout = () => {
    dispatch(setUser(["WEBPUBLIC", "WEBPUBLIC", false, "WEBPUBLIC", 3]))
  }

  const navigate = useNavigate()

  return (
    <div className="navbar__container">
      <CssBaseline/>
      <AppBar position="static">
        <Toolbar>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              alignItems: "center",
              alignContent: "center"
            }}
          >
            <Grid item sm={3} md={3} lg={3} xl={3} sx={{ display: "flex" }}>
              <Hidden smUp>
                <IconButton edge="start" color="inherit" aria-label="menu open drawer" onClick={() => setOpen(true)}>
                  <MenuIcon/>
                </IconButton>
              </Hidden>
              <img src={logo} alt="X-Raid Logo" className="x-raid logo navbar__logo" onClick={() => navigate("/Home")}/>
            </Grid>
            <Grid item sm={7} md={7} lg={7} xl={7} sx={{ display: "flex" }}>
              <Hidden smDown>
                <Nav
                  classes={{ tab: "navbar__link", tabs: "navbar__link-container" }}
                  tab={tab}
                  playerId={validationLogin ? UserAnagraficaId : null}
                  handleTabChange={handleTabChange}
                  sx={{ flexGrow: 1 }}
                />
              </Hidden>
            </Grid>
            <Grid item sm={2} md={2} lg={2} xl={2} sx={{ textAlign: "end" }}>
              <Hidden smDown>
                {
                  validationLogin ?
                    <Box
                      className="navbar__avatar-image"
                    >
                      <IconButton onClick={() => setAnchorEl(!anchorEl)} sx={{ p: 0 }}>
                        <Avatar alt={userName} src={imageBasePath + userAvatar}/>
                      </IconButton>
                      <StyledMenu
                        sx={{ mt: "45px" }}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right"
                        }}
                        id="demo-customized-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                      >
                        <MenuItem
                          component={Link}
                          to={"/Profilo/" + UserAnagraficaId + ""}
                          onClick={() => {
                            handleClose()
                            setTab(8) // Profilo tab
                          }}
                          disableRipple
                        >
                          {userName}
                        </MenuItem>
                        <Divider sx={{ my: 0.5 }}/>
                        <MenuItem component={Link} to={"/Login"} onClick={Logout} disableRipple>
                          Logout
                        </MenuItem>
                      </StyledMenu>
                    </Box>
                    :
                    <Button color="inherit" component={Link} to={"/Login"}>Login</Button>
                }
              </Hidden>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        sx={{
          width: 240,
          flexShrink: 0
        }}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <Toolbar/>
        <div className="navbar__drawer">
          {
            validationLogin ?
              <Avatar
                component={Link}
                to={"/Profilo/" + UserAnagraficaId + ""}
                onClick={handleClose}
                sx={{ margin: "0 auto" }}
                src={imageBasePath + userAvatar}
              />
              :
              <></>
          }
          <List>
            <ListItem>
              <Nav
                forMobile
                orientation="vertical"
                playerId={validationLogin ? UserAnagraficaId : null}
                tab={tab}
                handleTabChange={handleTabChange}
                sx={{ flexGrow: 1 }}
              />
            </ListItem>
          </List>
          <Divider/>
          <List>
            {
              validationLogin ?
                (
                  <ListItem
                    className="navbar__action-button"
                    component={Link} to="/Login"
                    onClick={() => {
                      Logout()
                      setOpen(false)
                    }}
                  >
                    <ListItemText primary="LOGOUT" sx={{ textAlign: "center" }}/>
                  </ListItem>
                )
                :
                (
                  <ListItem
                    className="navbar__action-button"
                    component={Link} to="/Login"
                    onClick={() => setOpen(false)}
                  >
                    <ListItemText primary="LOGIN" sx={{ textAlign: "center" }}/>
                  </ListItem>
                )
            }
          </List>
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default HeaderBar