import { useState } from "react"

import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { setUser } from "../features/counter/counterSlice"

import { Grid, TextField, Button, CircularProgress, Container, Alert, Typography } from "@mui/material"

import { apiBasePath, callProcessPath, dbName } from "./Utils/constants"

const Login = () => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")

  const [loading, setLoading] = useState(false)

  const [messageLogin, setMessageLogin] = useState({
    text: "",
    error: false
  })

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const fetchData = async() => {
    const url = `${apiBasePath}includes/io/Login.php?format=JSONP&username=${username}&password=${password}&dbname=${dbName}&language=en`

    let response = await fetch(url)

    if (!response.ok) {
      throw new Error("Data coud not be fetched!")
    } else {
      response = await (response.json())
      if (!response.success) {
        throw new Error(response.message)
      }

      let userData = await fetch(callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&playerID=" + response.UserAnagraficaId + "&language=&processid=app_userdata")
      userData = await userData.json()

      return ({ loginData: response, userData })
    }
  }

  const setLogin = () => {
    if ((username === "") || (password === "")) {
      setMessageLogin({
        text: "Inserire username o password",
        error: true
      })
    } else {
      setLoading(true)
      fetchData()
        .then(({ loginData: res, userData }) => {
          if (res.success) {
            // console.log({ res })
            setLoading(false)
            dispatch(setUser([
              username,
              password,
              true,
              res.UserName,
              res.UserAnagraficaId,
              userData.AVATAR
            ]))
            // console.log("PROVAAA", {
            //   condition: (!userData.AVATAR),
            //   avatar: userData.AVATAR
            // })

            if (!userData.AVATAR) {
              navigate("/Profilo/" + res.UserAnagraficaId + "?action=edit")
            } else {
              navigate("/Home")
            }

          } else {
            setLoading(false)
            setMessageLogin({ text: res.message, error: true })
          }

        })
        .catch((e) => {
          setLoading(false)
          setMessageLogin({ text: e.message, error: true })
          // console.log("[LOGIN FETCH]", { error: e.message })
        })
    }
  }

  return (
    <Container
      className="form__container"
      maxWidth="md"
    >
      <Grid
        container
        spacing={2}
        rowSpacing={2}
        justify="center"
        alignItems="center"
        className="glassmorphism"
        sx={{ padding: "3% 0", width: "fit-content", margin: "auto" }}
      >
        <Grid item xs={12}>
          <Typography marginLeft="2%" variant="h3" gutterBottom>
            Login
          </Typography>
        </Grid>
        <Grid item xs={12} className="form__item-container">
          <TextField
            required
            className="form__input"
            label="Username"
            error={messageLogin.error}
            onChange={(e) => {
              setMessageLogin({ error: false })
              setUsername(e.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} className="form__item-container">
          <TextField
            required
            className="form__input"
            label="Password"
            error={messageLogin.error}
            type={"password"}
            onChange={(e) => {
              setMessageLogin({ error: false })
              setPassword(e.target.value)
            }}
          />
        </Grid>
        {
          loading ?
            <Grid item xs={12} className="form__item-container">
              <CircularProgress className="circular-progress--centered"/>
            </Grid>
            :
            <>
              <Grid item xs={12} className="form__item-container">
                <Button variant="text" sx={{ textDecoration: "underline" }} component={Link}
                  to={"/ResetPassword"}> Hai dimenticato la password? </Button>
              </Grid>
              <Grid item xs={12} className="form__item-container">
                <Button size="large" variant="outlined" onClick={setLogin}> Login </Button>
              </Grid>
            </>
        }
        {messageLogin.error ? <Alert severity="error"
          sx={{ margin: "2% auto 0 auto" }}>{messageLogin.text}</Alert> : <></>}
      </Grid>
    </Container>
  )
}

export default Login

// {
//   loading ?
//     <div style={{ display: "flex", margin: "4% auto 2% auto" }}>
//       <br></br>
//       <CircularProgress className="circular-progress--centered"/>
//     </div>
//     :
//     <>
//       <Grid item xs={12} className="form__item-container">
//         <Button variant="text" sx={{ textDecoration: "underline" }} component={Link}
//                 to={"/ResetPassword"}> Hai dimenticato la password? </Button>
//       </Grid>
//       <Grid item xs={12} className="form__item-container">
//         <Button size="large" variant="outlined" onClick={setLogin}> Login </Button>
//       </Grid>
//     </>
// }