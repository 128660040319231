import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

import { Box, CircularProgress, Container, Typography, Button } from "@mui/material"

import { callProcess } from "../Utils/utils"

const HandleRefreshPassword = () => {

  const { token } = useParams()
  const [params] = useSearchParams()
  const navigate = useNavigate()
  const [res, setRes] = useState()

  useEffect(() => {
    const action = params.get("action") || "reset_password"
    callProcess({
      username: "WEBPUBLIC",
      password: "WEBPUBLIC",
      token,
      processid: action === "confirm_account" ? "confirm_accountact" : "app_resetpasswordact"
    }).then(res => {
      setRes(res)
    }).catch(err => {
      // eslint-disable-next-line no-console
      console.error({ err })
    })

  }, [token, params])

  return (
    <Container>
      <Box className="glassmorphism top-ranking__box"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Typography variant="h4"
          sx={{ margin: "1rem" }}>{params.get("action") === "confirm_account" ? "Conferma account" : "Reset password"}</Typography>
        <Typography variant="body1" sx={{ margin: "1rem" }}>
          {
            res ?
              `${res.message}. È stata inviata una mail con la nuova password` :
              <CircularProgress className="circular-progress--centered"/>
          }
        </Typography>
        <br/>
        {
          res?.status && res?.success && !res?.fail ?
            <Button
              onClick={() => navigate("/Login")}
              variant="outline"
              sx={{ textDecoration: "underline", margin: "auto" }}>Vai al login</Button>
            :
            <></>
        }
      </Box>
    </Container>
  )
}

export default HandleRefreshPassword
