import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import {
  Avatar, Paper, CircularProgress,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@mui/material"
import { Link } from "react-router-dom"

import { callProcess } from "../Utils/utils"

const TopRank = () => {
  const username = useSelector(state => state.counter.username)
  const password = useSelector(state => state.counter.password)
  const userId = useSelector(state => state.counter.userId)

  const [data, setData] = useState({
    data: [],
    isLoading: true
  })

  useEffect(() => {
    // const url = callProcessPath + "?format=JSON&username=" + username + "&password=" + password + "&dbname=" + dbName + "&ID=&userID=" + userId + "&language=&processid=app_toprank"
    callProcess({ username, password, userId, processid: "app_toprank" })
      .then((res) => {
        setData({ data: res, isLoading: false })
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e.message)
      })

  }, [password, userId, username])

  return (
    <TableContainer component={Paper} className="table__container--scrollable-in-view" sx={{ display: "flex" }}>
      {
        data.isLoading ?
          <CircularProgress className="circular-progress--centered"/>
          :
          <Table stickyHeader>
            <TableHead className="table__custom--head__opaque">
              <TableRow>
                <TableCell align="left" className="table__custom--cell">Giocatore</TableCell>
                <TableCell align="left" className="table__custom--cell">Rank</TableCell>
                <TableCell align="left" className="table__custom--cell">Punteggio</TableCell>
                {/* <TableCell align="left" className="table__custom--cell">Arena</TableCell>*/}
              </TableRow>
            </TableHead>
            <TableBody className="table__custom--body">
              {
                data?.data.dati && data.data.dati.map((toprank, index) => {
                  return (
                    <TableRow
                      className="table__custom--row"
                      component={Link} to={"/Profilo/" + toprank.user_id}
                      key={index}
                      sx={{ textDecoration: "none" }}
                    >
                      <TableCell
                        className="table__custom--cell__white"
                        component="th"
                        scope="row"
                        sx={{ display: "flex", alignItems: "center" }}
                        align="left"
                      >
                        <Avatar {...(toprank.avatar ? { src: toprank.avatar } : {})}/>&nbsp;&nbsp;{toprank.name}
                      </TableCell>
                      <TableCell className="table__custom--cell__white" align="left">{toprank.rank}</TableCell>
                      <TableCell className="table__custom--cell__white" align="left">{toprank.score}</TableCell>
                      {/* <TableCell className="table__custom--cell__white"*/}
                      {/*  align="left">{toprank.arenaprovenienza}</TableCell>*/}
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
      }
    </TableContainer>
  )
}

export default TopRank